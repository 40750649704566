<template>
  <v-container pa-0 ma-0 fluid class="home">
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <!-- Use .home-single-slide to hide carousel pagination -->
          <v-carousel height="auto" class="elevation-0">
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2024/08/Motorhome-Banner.png"
                gradient="to bottom, rgba(22,22,22,0.6), rgba(22,22,22,0.55) 15%, rgba(22,22,22,0.5) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <!--<h3 class="title mb-2">8 Days from $1,699* pp</h3>-->
                          <h1 class="white--text display-1 font-weight-bold mb-2">
                            Motorhome Madness
                          </h1>
                          
                          <h3 class="white--text title mb-1">Save 10% off the daily vehicle rate!</h3>
                          <v-btn
                            large
                            class="ml-0 mt-3 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            href="/deals/motorhome-madness/"
                          >Learn More</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-img>
            </v-carousel-item>
            
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2024/06/hero-au-book-2024-2025-gbp-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,0.25), rgba(22,22,22,0.15) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h3 class="title mb-2 kiwiGreen">30<sup>th</sup> Anniversary Edition</h3>
                          <h1 class="white--text display-1 font-weight-bold mb-2">
                            The Australia Book<br/>2024–2025
                          </h1>
                          
                          <h3 class="white--text title mb-1">The biggest Australia Book yet. View online or order from BrochureNet.</h3>
                          <v-btn
                            large
                            class="ml-0 mt-3 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            target="_blank"
                            href="https://issuu.com/holidayexperts/docs/theausbk2024-25_e-brochure_nzd?fr=sMzNhOTYzMjMxNDE"
                          >View Brochure</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only hidden-sm-and-up">
                  <small>-</small>
                </div>
              </v-img>
            </v-carousel-item>

            <!-- Slide #1 - NZ Book -->
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2024/04/hero-nz-book-2024-aud-nzd-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,0.25), rgba(22,22,22,0.15) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h3 class="title mb-2 kiwiGreen">30<sup>th</sup> Anniversary Edition</h3>
                          <h1 class="white--text display-1 font-weight-bold mb-2">
                            The New Zealand<br/>Book 2024–2025
                          </h1>
                          
                          <h3 class="white--text title mb-1">The most comprehensive NZ Book yet. View online or order from BrochureNet.</h3>
                          <v-btn
                            large
                            class="ml-0 mt-3 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            target="_blank"
                            href="https://issuu.com/holidayexperts/docs/new_zealand_book_e-brochure_2024-25_nzd_?fr=sNTdiNzYzMjMxNDE"
                          >View Brochure</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only hidden-sm-and-up">
                  <small>-</small>
                </div>
              </v-img>
            </v-carousel-item>
            <!-- Slide #3 - Western Australia -->
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Banner-3840-x-1390-WA-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,0.45) 15%, rgba(22,22,22,0.3) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h3 class="white--text title mb-1">Drive the Dream</h3>
                          <h1 class="white--text display-1 font-weight-bold mb-2">
                            Western Australia
                          </h1>                          
                          <h3 class="white--text title mb-1">WIN a $2,000* Western Australia Travel Voucher</h3>
                          <v-btn
                            large
                            class="ml-0 mt-3 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            href="/deals/western-australia/"
                          >View Deals</v-btn>
                          <v-divider class="ma-0 pa-0 hidden-sm-and-up" style="opacity:0;"></v-divider>
                          <v-btn
                            large
                            class="mt-3 mb-2 elevation-0 px-4"
                            color="white"
                            outline
                            target="_blank"
                            href="https://cdn.anzcro.com.au/wp-content/uploads/2024/05/WA-incentive-Flyer.pdf"
                          >How to Win</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Roebuck Bay, Broome - Western Australia</small>
                </div>
              </v-img>
            </v-carousel-item>

            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2023/09/hero-luxury-brochure-23-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,0.25), rgba(22,22,22,0.15) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-2">
                            Travel beyond the ordinary
                          </h1>                          
                          <h3 class="white--text title mb-1">Discover a collection of Australia and New Zealand’s most spectacular luxury properties.</h3>
                          <v-btn
                            large
                            class="ml-0 mt-3 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            target="_blank"
                            href="https://issuu.com/holidayexperts/docs/issuu_anzcro_luxury_23-24_nzd?fr=xKAE9_zU1NQ"
                          >View Brochure</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only hidden-sm-and-up">
                  <small>Brochure Cover: Funyaks, Dart River Adventures</small>
                </div>
              </v-img>
            </v-carousel-item>

            <!-- Slide #2 - Ski Brochure -->
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2024/01/hero-winter-holiday-collection-2024-adjusted-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,0.5), rgba(22,22,22,0.1) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h3 class="white--text title mb-1">New Zealand</h3>
                          <h1 class="white--text display-1 font-weight-bold mb-2">
                            Winter Holiday<br/>Collection | 2024
                          </h1>                          
                          <h3 class="white--text title mb-1">Explore snow holiday packages and need-to-know info for the 2024 season.</h3>
                          <v-btn
                            large
                            class="ml-0 mt-3 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            href="/deals/new-zealand-ski-deals/"
                          >View Deals</v-btn>
                          <v-divider class="ma-0 pa-0 hidden-sm-and-up" style="opacity:0;"></v-divider>
                          <v-btn
                            large
                            class="mt-3 mb-2 elevation-0 px-4"
                            color="white"
                            outline
                            target="_blank"
                            href="https://issuu.com/holidayexperts/docs/whc_nzd_issuu_2024?fr=xKAE9_zU1NQ"
                          >View Brochure</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Harris Mountain Heli-Skiing</small>
                </div>
              </v-img>
            </v-carousel-item>
            <!-- Slide #1 - Great Rail Journeys -->
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2023/07/hero-tranzalpine-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,0.6), rgba(22,22,22,0.55) 15%, rgba(22,22,22,0.5) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <!--<h3 class="title mb-2">8 Days from $1,699* pp</h3>-->
                          <h1 class="white--text display-1 font-weight-bold mb-2">
                            Great Rail Journeys <br/>of New Zealand
                          </h1>
                          
                          <h3 class="white--text title mb-1">Discover a collection of our most popular self-guided New Zealand Rail packages.</h3>
                          <v-btn
                            large
                            class="ml-0 mt-3 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            href="/deals/rail-journeys/"
                          >View Deals</v-btn>
                          <v-divider class="ma-0 pa-0 hidden-sm-and-up" style="opacity:0;"></v-divider>
                          <v-btn
                            large
                            class="mt-3 mb-2 elevation-0 px-4"
                            color="white"
                            outline
                            target="_blank"
                            href="https://issuu.com/holidayexperts/docs/rail_nzd_2024_issuu?fr=xKAE9_zU1NQ"
                          >View Brochure</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>TranzAlpine Train Journey - Photo: KiwiRail</small>
                </div>
              </v-img>
            </v-carousel-item>
            <!-- Slide #1 - Luxury Brochure -->
            <!--<v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2023/09/hero-luxury-brochure-23-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,0.25), rgba(22,22,22,0.15) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-2">
                            Travel beyond<br/>the ordinary
                          </h1>                          
                          <h3 class="white--text title mb-1">Discover a collection of Australia and New Zealand’s most spectacular luxury properties.</h3>
                          <v-btn
                            large
                            class="ml-0 mt-3 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            target="_blank"
                            href="https://issuu.com/holidayexperts/docs/issuu_anzcro_luxury_23-24_nzd?fr=xKAE9_zU1NQ"
                          >View Brochure</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only hidden-sm-and-up">
                  <small>--</small>
                </div>
              </v-img>
            </v-carousel-item>-->
            <!-- Slide #3 - South Pacific -->
            <!--
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2022/01/hero-south-pacific-cook-islands-2-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,0.35) 15%, rgba(22,22,22,0.35) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>                         
                          <h1 class="white--text display-1 font-weight-bold mb-2">
                            South Pacific<br/>Island Paradise
                          </h1>  
                          <h3 class="white--text title mb-3">Find paradise in the South Pacific with these special offers</h3>
                          <v-btn
                            large
                            dark
                            class="ml-0 mt-3 elevation-0"
                            :color="kiwiGreen"
                            to="/deals/south-pacific/"
                          >View Deals</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only hidden-sm-and-up">
                  <small></small>
                </div>
              </v-img>
            </v-carousel-item>
            -->
            <!-- Slide #1 - NSW -->
            <!--
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2023/09/hero-deals-new-south-wales-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,0.5), rgba(22,22,22,0.45) 15%, rgba(22,22,22,0.4) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-2">
                            Discover<br/>Sydney & Beyond
                          </h1>                          
                          <h3 class="white--text title mb-1">From hillsides to harbours to history, the ultimate NSW holiday starts here</h3>
                          <v-btn
                            large
                            class="ml-0 mt-3 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            href="/deals/new-south-wales/"
                          >View Deals</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Sydney Harbour, Sydney - Photo: Destination NSW</small>
                </div>
              </v-img>
            </v-carousel-item>
            -->
            <!-- Slide #1 - Aussie Highlights -->
            <!--<v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2023/07/hero-palm-cove-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,0.5), rgba(22,22,22,0.45) 15%, rgba(22,22,22,0.4) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-2">
                            Aussie<br/>Highlights
                          </h1>                          
                          <h3 class="white--text title mb-1">Add a special stay onto your holiday down under</h3>
                          <v-btn
                            large
                            class="ml-0 mt-3 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            href="/deals/australia-highlights/"
                          >View Deals</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only hidden-sm-and-up">
                  <small>Brochure Cover: Funyaks, Dart River Adventures</small>
                </div>
              </v-img>
            </v-carousel-item>-->
            <!-- Slide #4 - Brochure -->
            <!--<v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2023/05/hero-brochure-au-book-1-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,0.35), rgba(22,22,22,0.15) 15%, rgba(22,22,22,0.05) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">
                            Australia, like you've<br/>never seen her before
                          </h1>
                          <h3 class="white--text title mb-1">The Australia Book 2023-2024 is here</h3>
                          <small class="d-block mb-4">View online or order your copy at <b>BROCHURENET</b></small>
                          <v-btn
                            large
                            class="ml-0 mt-2 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            target="_blank"
                            href="https://issuu.com/holidayexperts/docs/issuu_ausbook_nzd_23_24?fr=sYTFiNDU3MTE2OTE"
                          >View Brochure</v-btn>
                          <v-divider class="ma-0 pa-0 hidden-sm-and-up" style="opacity:0;"></v-divider>
                          <v-btn
                            large
                            class="mt-2 mb-2 elevation-0 px-4"
                            color="white"
                            outline
                            href="/deals/australia-book/"
                          >View Deals</v-btn>
                          <small class="d-block">Or order your copy at <b>Menzies</b></small>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Kakadu National Park - Photo: Tourism NT/Salty Wings</small>
                </div>
              </v-img>
            </v-carousel-item>
            -->
            <!-- Slide #1 - South Australia -->
            <!--
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2023/06/hero-south-australia-2-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.25) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <img src="https://cdn.anzcro.com.au/wp-content/uploads/2022/06/south-australia-deals-inline.png" alt="Reward Your Wonder in South Australia" class="deal-graphic holidays-at-home mb-3" width="540" height="120" />
                          <h3 class="white--text title mb-3">South Australia offers experiences that will reward your wonder around every turn.</h3>
                          <v-btn
                            large
                            class="ml-0 mt-2 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            href="/deals/south-australia/"
                          >View Deals</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Adelaide, South Australia</small>
                </div>
              </v-img>
            </v-carousel-item>-->
            <!-- Slide #1 - Ski Deals -->
            <!--<v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2023/01/hero-ski-deals-2023-a-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.45) 15%, rgba(22,22,22,0.25) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <img src="https://cdn.anzcro.com.au/wp-content/uploads/2023/01/new-zealand-ski-deals-2023-inline.png" alt="New Zealand Ski Deals 2023" class="deal-graphic holidays-at-home mb-3" width="540" height="120" />
                          <h3 class="white--text title mb-3">2023 Early Bird Ski Offers now available including Queenstown, Wanaka & Mt Hutt</h3>
                          <v-btn
                            large
                            class="ml-0 mt-2 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            href="/deals/new-zealand-ski-deals/"
                          >View Deals</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Mt Hutt, Methven - Photo: NZSki</small>
                </div>
              </v-img>
            </v-carousel-item>-->         
            <!-- Slide #2 - Ski Brochure -->
            <!--<v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2023/01/hero-winter-collection-2023-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,0.5), rgba(22,22,22,0.1) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <img src="https://cdn.anzcro.com.au/wp-content/uploads/2023/01/new-zealand-winter-holiday-collection-inline.png" alt="New Zealand Winter Holiday Collection" class="deal-graphic title-320 mb-3" width="540" height="160" />
                          <h3 class="white--text title mb-4">A collection of New Zealand winter holiday packages, early bird lift passes and all things ski!</h3>
                          <v-btn
                            large
                            class="ml-0 mt-2 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            target="_blank"
                            href="https://issuu.com/holidayexperts/docs/nzd_winter_holidays_collection_digital?fr=sNjI1MjU3MTE2OTE"
                          >View Brochure</v-btn>
                          <small class="d-block">Or order your copy at <b>TIFs</b></small>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Brochure Cover: Ski the Tasman Glacier, Mt Cook</small>
                </div>
              </v-img>
            </v-carousel-item>-->
            <!-- Slide #5 - Warm Up in Australia -->
            <!--<v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2023/03/hero-warm-up-australia-2023-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,0.65), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.25) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <img src="https://cdn.anzcro.com.au/wp-content/uploads/2022/04/warm-up-in-australia-inline.png" alt="Warm Up in Australia" class="deal-graphic holidays-at-home mb-3" width="540" height="120" />
                          <h3 class="white--text title mb-3">Ditch the grey skies and follow the sun with our latest tropical holiday collection</h3>
                          <v-btn
                            large
                            class="ml-0 mt-2 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            href="/deals/warm-up-australia/"
                          >View Deals</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Hill Inlet, Whitehaven Beach - Photo: Tourism Australia</small>
                </div>
              </v-img>
            </v-carousel-item>-->
            <!-- Slide #4 - Brochure -->
            <!--
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2022/10/hero-nz-book-2022-24-v2-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,0.5), rgba(22,22,22,0.1) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">
                            The NZ Bible is Back
                          </h1>
                          <h3 class="white--text title mb-4">The New Zealand Book 2022-2024 is now available</h3>
                          <v-btn
                            large
                            class="ml-0 mt-2 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            target="_blank"
                            href="https://issuu.com/holidayexperts/docs/nzd_issuu_nz_brochure_2022-24?fr=sMjc1NjE0NDc5NjU"
                          >View Brochure</v-btn>
                          <small class="d-block">Or order your copy at <b>BrochureNet</b></small>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Brochure Cover: Funyaks, Dart River Adventures</small>
                </div>
              </v-img>
            </v-carousel-item>
            -->
            <!-- Slide #1 - Signature Australia Deals -->
            <!--
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2022/08/hero-signature-australia-tas-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.2) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <img src="https://cdn.anzcro.com.au/wp-content/uploads/2022/08/signature-australian-experiences-stacked-hp.png" alt="Signature Australian Experiences Collection" class="deal-graphic stacked mb-3" width="240" height="150"  />
                          <h3 class="white--text title mb-3">A carefully curated selection of Australia’s<br/>most outstanding holidays</h3>
                          <v-btn
                            large
                            class="ml-0 mt-2 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            href="/deals/signature-australia/"
                          >View Deals</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Wineglass Bay, Freycinet Peninsula - Tasmania</small>
                </div>
              </v-img>
            </v-carousel-item>-->
            <!-- Slide #5 - NZ Deals -->
            <!--
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2022/04/hero-deals-new-zealand-holidays-at-home-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.25) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <img src="https://cdn.anzcro.com.au/wp-content/uploads/2022/04/new-zealand-holidays-at-home-inline.png" alt="New Zealand Holidays at Home" class="deal-graphic holidays-at-home mb-2" width="540" height="120" />
                          <h3 class="white--text title mb-3">Explore Aotearoa, from the iconic sights to the hidden delights</h3>
                          <v-btn
                            large
                            dark
                            class="ml-0 mt-3 elevation-0"
                            :color="kiwiGreen"
                            to="/deals/new-zealand/"
                          >View Deals</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Milford Sound, Southland - Credit: Great South</small>
                </div>
              </v-img>
            </v-carousel-item>-->
            <!-- Slide #3 - Classic Aussie Holidays -->
            <!--<v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2022/06/hero-deals-classic-australia-hp-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.25) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <img src="https://cdn.anzcro.com.au/wp-content/uploads/2022/06/classic-australian-holidays-inline.png" alt="Classic Australian Holidays" class="deal-graphic title-320 mb-3" width="540" height="160" />
                          <h3 class="white--text title mb-3">A showcase of must-do Aussie holidays in iconic destinations</h3>
                          <v-btn
                            large
                            class="ml-0 mt-2 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            href="/deals/classic-australia/"
                          >View Deals</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Sunrise in the Hunter River - Photo: Kimberley Cruise</small>
                </div>
              </v-img>
            </v-carousel-item>-->
            <!-- Slide #2 - Hurunui Deals -->
            <!--<v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2022/08/hero-secrets-of-hurunui-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.65) 15%, rgba(22,22,22,0.35) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <img src="https://cdn.anzcro.com.au/wp-content/uploads/2022/08/secrets-of-hurunui-inline.png" alt="Secrets of Hurunui" class="deal-graphic holidays-at-home mb-3" width="540" height="120" />
                          <h3 class="white--text title mb-3">Discover endless reasons to visit<br/>South Island’s hidden gem</h3>
                          <v-btn
                            large
                            class="ml-0 mt-2 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            href="/deals/hurunui/"
                          >View Deals</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Black Estate, North Canterbury</small>
                </div>
              </v-img>
            </v-carousel-item>-->
            <!-- Slide #2 - Kaikoura -->
            <!--<v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2022/05/hero-visit-kaikoura-this-winter-3-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.25) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <img src="https://cdn.anzcro.com.au/wp-content/uploads/2022/05/visit-kaikoura-this-winter-inline.png" alt="Visit Kaikoura this Winter" class="deal-graphic holidays-at-home mb-3" width="540" height="120" />
                          <h3 class="white--text title mb-3">Forget the crowds and cosy up to wildlife this winter</h3>
                          <v-btn
                            large
                            class="ml-0 mt-2 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            href="/deals/kaikoura/"
                          >View Deals</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Dolphins playing at sunset - Photo: Kaikoura Kayaks</small>
                </div>
              </v-img>
            </v-carousel-item>-->
            <!-- Slide #3 - AU Book -->
            <!--<v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2021/05/home-hero-au-package-collection-brochure-2021-nzd-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">
                            The Australia<br/>Package Collection
                          </h1>
                          <h3 class="white--text title mb-4">56 pages of epic Australian holiday packages</h3>
                          <v-btn
                            large
                            class="ml-0 mt-2 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            target="_blank"
                            href="https://issuu.com/holidayexperts/docs/ausbook_2021-22_nzd_web?fr=sNDliMjE0NDc5NjU"
                          >View Brochure</v-btn>
                          <v-divider class="ma-0 pa-0 hidden-sm-and-up" style="opacity:0;"></v-divider>
                          <v-btn
                            large
                            class="mt-2 mb-2 elevation-0 px-4"
                            color="white"
                            outline
                            to="/deals/australia-package-collection/"
                          >View Deals</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small></small>
                </div>
              </v-img>
            </v-carousel-item>-->
            <!-- Slide #4 - Connect Register -->
            <!--<v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2021/05/home-hero-au-fiordland-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">
                            Self-Service
                            <br />Booking Platform
                          </h1>
                          <h3 class="white--text title mb-4">A new level of convenience for Travel Agents</h3>
                          <v-btn
                            large
                            class="ml-0 mt-2 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            to="/register/"
                          >Register Now</v-btn>
                          <v-divider class="ma-0 pa-0 hidden-sm-and-up" style="opacity:0;"></v-divider>
                          <v-btn
                            large
                            class="mt-2 mb-2 elevation-0 px-4"
                            color="white"
                            outline
                            to="/login/"
                          >Login</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Milford Sound, Fiordland - Photo: Matt Crawford</small>
                </div>
              </v-img>
            </v-carousel-item>-->
            <!-- Slide #4 - Canterbury -->
            <!--<v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2021/04/home-hero-mid-canterbury-mackenzie-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.45) 15%, rgba(22,22,22,0.45) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center text-md-left class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <h1 class="white--text display-1 font-weight-bold mb-3">
                            A World of<br/>Winter Adventures
                          </h1>
                          <h3 class="white--text title mb-4">Discover Mid Canterbury and the Mackenzie Region.</h3>
                          <v-btn
                            large
                            class="ml-0 mt-2 mb-2 elevation-0"
                            dark
                            :color="kiwiGreen"
                            to="/the-insider/discover-mid-canterbury-mackenzie-nz/"
                          >Read More</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Mount John Stargazing Observatory, Tekapo, New Zealand</small>
                </div>
              </v-img>
            </v-carousel-item>-->
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>

    <!--<v-layout row class="home-section-why">
      <v-flex>
        <v-card dark :color="kiwiGreen" flat tile class="transparent">
          <v-container text-xs-center>
            <v-layout row>
              <v-flex xs12 py-1>
                <h2 class="display-1 font-weight-medium">The New Zealand Holiday Experts</h2>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>-->

    <v-layout row class="home-section-cards">
      <v-flex>
        <v-card dark :color="coalBlack" flat tile class="transparent">
          <v-container grid-list-lg>
            <v-layout row wrap align-center>
              <v-flex xs12 lg3 text-xs-center>
                <v-img
                  src="https://cdn.anzcro.com.au/wp-content/uploads/2020/01/anzcro-connect-inverted.svg"
                  width="160"
                  class="my-2 d-inline-block"
                ></v-img>
                <v-btn
                  large
                  class="my-2 mx-0 elevation-0 hidden-md-and-down"
                  color="white"
                  outline
                  to="/register/"
                >Register</v-btn>
              </v-flex>
              <v-flex xs12 hidden-sm-and-up>
                <v-divider></v-divider>
              </v-flex>
              <v-flex xs12 lg9 class="home-hero-cards">
                <v-layout row wrap>
                  <v-flex xs12 sm6 md3 class="connect-icon-item">
                    <v-layout row wrap align-center>
                      <v-flex xs3 md12 text-xs-center>
                        <img
                          height="60"
                          :src="require('@/assets/img/icons/display/A01_Search_NZ_Inventory.svg')"
                        />
                      </v-flex>
                      <v-flex xs9 md12 px-2 text-md-center>
                        <span class="subheading font-weight-medium">
                          Large inventory of
                          <br />New Zealand and
                          <br />Australian products
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 hidden-sm-and-up>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs12 sm6 md3 class="connect-icon-item">
                    <v-layout row wrap align-center>
                      <v-flex xs3 md12 text-xs-center>
                        <img
                          height="60"
                          :src="require('@/assets/img/icons/display/A02_Quicky_Itineraries.svg')"
                        />
                      </v-flex>
                      <v-flex xs9 md12 px-2 text-md-center>
                        <span class="subheading font-weight-medium">
                          Book multiple products
                          <br />in a single transaction
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 hidden-sm-and-up>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs12 sm6 md3 class="connect-icon-item">
                    <v-layout row wrap align-center>
                      <v-flex xs3 md12 text-xs-center>
                        <img
                          height="60"
                          :src="require('@/assets/img/icons/display/A03_Easy_Quotes.svg')"
                        />
                      </v-flex>
                      <v-flex xs9 md12 px-2 text-md-center>
                        <span class="subheading font-weight-medium">
                          Easily manage
                          <br />quotes & bookings
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 hidden-sm-and-up>
                    <v-divider></v-divider>
                  </v-flex>
                  <v-flex xs12 sm6 md3 class="connect-icon-item">
                    <v-layout row wrap align-center>
                      <v-flex xs3 md12 text-xs-center>
                        <img
                          height="60"
                          :src="require('@/assets/img/icons/display/A04_Reports.svg')"
                        />
                      </v-flex>
                      <v-flex xs9 md12 px-2 text-md-center>
                        <span class="subheading font-weight-medium">
                          24/7 access to
                          <br />client documentation
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
              <v-flex xs12 text-xs-center mt-3 hidden-lg-and-up>
                <v-btn
                  large
                  class="ml-0 my-2 elevation-0"
                  dark
                  :color="kiwiGreen"
                  to="/register/"
                >Register</v-btn>
                <v-btn
                  large
                  class="my-2 mr-0 elevation-0 px-4"
                  color="white"
                  outline
                  to="/login/"
                >Login</v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>


    <!--
    <v-layout row class="home-section-about">
      <v-flex>
        <v-card flat tile>
          <v-container text-xs-center>
            <v-layout row wrap>
              <v-flex xs12 lg10 pt-5 offset-lg1>
                <h3 class="home-section-title green-on-white">
                  <span>About Anzcro</span>
                </h3>
                <span
                  class="about-quote headline d-block font-weight-bold mb-5"
                >We love helping Travel Agents provide their customers with extraordinary holidays in New Zealand.</span>
              </v-flex>
              <v-flex xs12>
                <p
                  class="text-xs-left mx-2"
                >For over 20 years our dedicated team has lived and breathed New Zealand - our vast knowledge and deep understanding of the destination is unparalleled. We are proud to give Travel Agents the edge when it comes to selling New Zealand holidays with our market-leading range of over 5,000 travel products provided by hundreds of New Zealand operators.</p>
                <p
                  class="text-xs-left mx-2"
                >Whether your customers want to enjoy the ultimate freedom of a self-drive holiday, experience the thrill of skiing in a winter wonderland, or immerse themselves in the natural beauty on a guided walk, ANZCRO’s team of New Zealand specialists will ensure each itinerary is meticulously planned to cater for a variety of traveller’s needs.</p>
                <p
                  class="text-xs-left mx-2"
                >ANZCRO’s ‘boots-on-the-ground’ approach, and state-of-the-art technology, gives you 24/7 access to our expertise and the best New Zealand booking solution for your business. By working with us, you can rest assured your customers are in good hands.</p>
                <v-avatar :color="lightGrey" size="70" class="mt-4 mb-2">
                  <img
                    src="https://cdn.anzcro.com.au/wp-content/uploads/2019/11/nick-guthrey-avatar.png"
                    alt="Nick Guthrey, CEO"
                  />
                </v-avatar>
                <span class="d-block mb-5">Nick Guthrey, CEO</span>
                <v-btn
                  large
                  dark
                  to="/about/"
                  :color="headerGrey"
                  outline
                  class="mt-0 mx-auto elevation-0 mb-5"
                >Find out more about ANZCRO</v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    -->

    <v-layout row class="home-section-why">
      <v-flex>
        <v-card dark :color="kiwiGreen" flat tile class="transparent">
          <v-container text-xs-center>
            <v-layout row>
              <v-flex xs12 py-5>
                <h3 class="home-section-title white-on-green">
                  <span>Why Choose Anzcro</span>
                </h3>
                <!--<v-img :src="require('@/assets/img/home-hero-devices-green.png')" class="mx-auto"></v-img>-->
                <v-card class="elevation-10 py-0">
                  <v-responsive :aspect-ratio="16/9">
                    <div style="padding:56.25% 0 0 0;position:relative;"><iframe src="https://player.vimeo.com/video/377428455?title=0&byline=0&portrait=0" style="position:absolute;top:0;left:0;width:100%;height:100%;" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe></div>
                  </v-responsive>
                </v-card>
                <v-layout row wrap text-xs-left mt-5>
                  <v-flex xs12 sm6 md4 my-3 px-3>
                    <v-layout row>
                      <v-flex xs2 text-xs-right pr-3>
                        <v-avatar size="24" color="#5A992E" class="mt-2">
                          <v-icon size="18">check</v-icon>
                        </v-avatar>
                      </v-flex>
                      <v-flex xs10>
                        <span class="subheading font-weight-medium">
                          The largest inventory of New Zealand
                          <br />and Australian travel product
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md4 my-3 px-3>
                    <v-layout row>
                      <v-flex xs2 text-xs-right pr-3>
                        <v-avatar size="24" color="#5A992E" class="mt-2">
                          <v-icon size="18">check</v-icon>
                        </v-avatar>
                      </v-flex>
                      <v-flex xs10>
                        <span class="subheading font-weight-medium">
                          Directly contracted hotels
                          <br />with competitive rates
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md4 my-3 px-3>
                    <v-layout row>
                      <v-flex xs2 text-xs-right pr-3>
                        <v-avatar size="24" color="#5A992E" class="mt-2">
                          <v-icon size="18">check</v-icon>
                        </v-avatar>
                      </v-flex>
                      <v-flex xs10>
                        <span class="subheading font-weight-medium">
                          Pre-built holiday packages
                          <br />ready to book
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md4 my-3 px-3>
                    <v-layout row>
                      <v-flex xs2 text-xs-right pr-3>
                        <v-avatar size="24" color="#5A992E" class="mt-2">
                          <v-icon size="18">check</v-icon>
                        </v-avatar>
                      </v-flex>
                      <v-flex xs10>
                        <span class="subheading font-weight-medium">
                          Dynamic pricing with
                          <br />live availability
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md4 my-3 px-3>
                    <v-layout row>
                      <v-flex xs2 text-xs-right pr-3>
                        <v-avatar size="24" color="#5A992E" class="mt-2">
                          <v-icon size="18">check</v-icon>
                        </v-avatar>
                      </v-flex>
                      <v-flex xs10>
                        <span class="subheading font-weight-medium">
                          Quick turnaround for
                          <br />booking confirmations
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md4 my-3 px-3>
                    <v-layout row>
                      <v-flex xs2 text-xs-right pr-3>
                        <v-avatar size="24" color="#5A992E" class="mt-2">
                          <v-icon size="18">check</v-icon>
                        </v-avatar>
                      </v-flex>
                      <v-flex xs10>
                        <span class="subheading font-weight-medium">
                          Automation enables live
                          <br />booking notification alerts
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md4 my-3 px-3>
                    <v-layout row>
                      <v-flex xs2 text-xs-right pr-3>
                        <v-avatar size="24" color="#5A992E" class="mt-2">
                          <v-icon size="18">check</v-icon>
                        </v-avatar>
                      </v-flex>
                      <v-flex xs10>
                        <span class="subheading font-weight-medium">
                          24/7 access to quote, amend
                          <br />& book through online system
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 sm6 md4 my-3 px-3>
                    <v-layout row>
                      <v-flex xs2 text-xs-right pr-3>
                        <v-avatar size="24" color="#5A992E" class="mt-2">
                          <v-icon size="18">check</v-icon>
                        </v-avatar>
                      </v-flex>
                      <v-flex xs10>
                        <span class="subheading font-weight-medium">
                          Expert reservations support
                          via live chat, email & phone
                        </span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout row class="home-reservations">
      <v-flex>
        <v-card flat tile>
          <v-layout row wrap align-space-around>
            <v-flex xs12 md6 class="home-reservations-image">
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2019/11/anzcro-call-center-temp.jpg"
                height="100%"
                position="top left"
              ></v-img>
            </v-flex>
            <v-flex xs12 md6 class="home-reservations-content" align-self-center>
              <v-layout row wrap my-5>
                <v-flex xs12>
                  <h3 class="home-section-title reservations green-on-white">
                    <span>The Destination Experts</span>
                  </h3>
                  <p>Highly specialised consultants with in-depth New Zealand and Australian knowledge.</p>
                </v-flex>
                <v-flex xs12>
                  <h3 class="home-section-title reservations green-on-white">
                    <span>Assistance on the Ground</span>
                  </h3>
                  <p>Client assistance and support through offices in AU and in NZ.</p>
                </v-flex>
                <v-flex xs12>
                  <h3 class="home-section-title reservations green-on-white">
                    <span>Commitment & Attention to Detail</span>
                  </h3>
                  <p
                    class="mb-0"
                  >Our expert team go the extra mile to ensure your clients have the best vacation experiences.</p>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout row class="home-section-product">
      <v-flex>
        <v-card dark :color="coalBlack" flat tile>
          <v-img
            src="https://cdn.anzcro.com.au/wp-content/uploads/2019/11/home-ruapehu-1920w.jpg"
            gradient="to bottom, rgba(48,48,47,.9), rgba(22,22,22,0.95)"
            class="home-product-bg"
          >
            <v-container text-xs-center fill-height>
              <v-layout row align-center>
                <v-flex xs12 sm10 md12 py-5 offset-sm1 offset-md0>
                  <h3 class="home-section-title white-on-dark">
                    <span>Our Product Range</span>
                  </h3>
                  <v-layout row wrap>
                    <v-flex xs6 sm4 md3 text-xs-center offset-md32 class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B01_Accommodation.svg')"
                      />
                      <span class="subheading d-block mt-2">Accommodation</span>
                    </v-flex>
                    <v-flex xs6 sm4 md3 text-xs-center class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B02_FIT_Packages.svg')"
                      />
                      <span class="subheading d-block mt-2">FIT Packages</span>
                    </v-flex>
                    <v-flex xs6 sm4 md3 text-xs-center class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B03_Ski_Holidays.svg')"
                      />
                      <span class="subheading d-block mt-2">Ski Holidays</span>
                    </v-flex>
                    <v-flex xs6 sm4 md3 text-xs-center class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B04_Rental_Vehicles.svg')"
                      />
                      <span class="subheading d-block mt-2">
                        Rental Cars
                        <br />& Campers
                      </span>
                    </v-flex>
                    <v-flex xs6 sm4 md3 text-xs-center class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B05_DayTours.svg')"
                      />
                      <span class="subheading d-block mt-2">
                        Day Tours &
                        <br />Activities
                      </span>
                    </v-flex>
                    <v-flex xs6 sm4 md3 text-xs-center class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B06_MultiDayScenicTours.svg')"
                      />
                      <span class="subheading d-block mt-2">
                        Multiday
                        <br />Scenic Tours
                      </span>
                    </v-flex>
                    <v-flex xs6 sm4 md3 text-xs-center class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B07_Transfers.svg')"
                      />
                      <span class="subheading d-block mt-2">
                        Transfers &
                        <br />Intercity Travel
                      </span>
                    </v-flex>
                    <v-flex xs6 sm4 md3 offset-md32 text-xs-center class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B08_Walks_and_Hikes.svg')"
                      />
                      <span class="subheading d-block mt-2">Walks & Hikes</span>
                    </v-flex>
                    <v-flex xs6 sm4 md3 text-xs-center class="product-range-item">
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B09_Cruise_Shore_Excursions.svg')"
                      />
                      <span class="subheading d-block mt-2">
                        Cruise Shore
                        <br />Excursions
                      </span>
                    </v-flex>
                    <v-flex
                      xs6
                      sm4
                      md3
                      offset-sm4
                      offset-md0
                      text-xs-center
                      class="product-range-item"
                    >
                      <img
                        height="60"
                        :src="require('@/assets/img/icons/display/B10_Rail_Journeys.svg')"
                      />
                      <span class="subheading d-block mt-2">Rail Journeys</span>
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-container>
          </v-img>
        </v-card>
      </v-flex>
    </v-layout>

    <!--
    <v-layout row class="home-section-testimonials">
      <v-flex>
        <v-card :color="lightGrey" flat tile class="card-wrapper">
          <v-container text-xs-center>
            <v-layout row>
              <v-flex xs12 py-5>
                <h3 class="home-section-title green-on-white">
                  <span>Words from our partners</span>
                </h3>
                <v-card flat :color="lightGrey">
                  <v-window v-model="testimonial" height="auto">
                    <v-window-item key="card-1">
                      <v-card :color="lightGrey" flat height="300">
                        <v-layout align-center justify-center fill-height>
                          <v-flex>
                            <span class="headline d-block font-weight-medium hp-testimonial">
                              <q>My clients had a fantastic time on their ANZCRO holiday and everything went perfectly, thank you! As a matter of fact they have referred another client for their proposed trip to New Zealand and I have put through a quote request through for this.</q>
                            </span>
                            <v-avatar :color="lightGrey" size="40" class="mt-4 mb-2">
                              <img
                                src="https://cdn.anzcro.com.au/wp-content/uploads/2019/11/travel-counsellors-avatar.png"
                                alt="Travel Counsellors Logo"
                              />
                            </v-avatar>
                            <span class="d-block mb-5">Rebecca, Travel Counsellors</span>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-window-item>
                    <v-window-item key="card-2">
                      <v-card :color="lightGrey" flat height="300">
                        <v-layout align-center justify-center fill-height>
                          <v-flex>
                            <span class="headline d-block font-weight-medium hp-testimonial">
                              <q>Thanks for all your help with my booking – the whole ANZCRO reservation team has really gone above and beyond to help me and I’m super appreciative.</q>
                            </span>
                            <v-avatar :color="lightGrey" size="40" class="mt-4 mb-2">
                              <img
                                src="https://cdn.anzcro.com.au/wp-content/uploads/2019/11/raa-avatar.png"
                                alt="RAA Logo"
                              />
                            </v-avatar>
                            <span class="d-block mb-5">Michael, RAA Oaklands Park</span>
                          </v-flex>
                        </v-layout>
                      </v-card>
                    </v-window-item>
                  </v-window>
                  <v-card-actions class="justify-space-between">
                    <v-btn flat @click="prev">
                      <v-icon>chevron_left</v-icon>
                    </v-btn>
                    <v-item-group v-model="testimonial" class="text-xs-center" mandatory>
                      <v-item v-for="n in length" :key="`btn-${n}`">
                        <v-btn
                          slot-scope="{ active, toggle }"
                          :input-value="active"
                          icon
                          @click="toggle"
                        >
                          <v-icon size="10">lens</v-icon>
                        </v-btn>
                      </v-item>
                    </v-item-group>
                    <v-btn flat @click="next">
                      <v-icon>chevron_right</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
    -->


    <v-layout row class="home-section-suppliers">
      <v-flex>
        <v-card flat tile :color="offWhite">
          <v-container text-xs-center>
            <v-layout row>
              <v-flex xs12 py-5>
                <h3 class="home-section-title green-on-white mb-5">
                  <span>Some of our great suppliers</span>
                </h3>
                <v-layout row wrap mb-4>
                  <v-flex xs6 sm4 md3 lg2 py-4>
                    <img width="100" class="mx-auto" :src="require('@/assets/img/logos/thl.png')" />
                  </v-flex>
                  <v-flex xs6 sm4 md3 lg2 py-4>
                    <img width="100" class="mx-auto" src="https://cdn.anzcro.com.au/wp-content/uploads/2022/12/realnz-1.png" />
                  </v-flex>
                  <v-flex xs6 sm4 md3 lg2 py-4>
                    <img width="100" class="mx-auto" :src="require('@/assets/img/logos/budget.svg')" />
                  </v-flex>
                  <v-flex xs6 sm4 md3 lg2 py-4>
                    <img width="100" class="mx-auto" :src="require('@/assets/img/logos/nzski.svg')" />
                  </v-flex>
                  <v-flex xs6 sm4 md3 lg2 py-4>
                    <img width="100" class="mx-auto" src="https://cdn.anzcro.com.au/wp-content/uploads/2020/08/ihg.png" />
                  </v-flex>
                  <v-flex xs6 sm4 md3 lg2 py-4>
                    <img width="100" class="mx-auto" :src="require('@/assets/img/logos/accor-hotels.svg')" />
                  </v-flex>
                  <v-flex xs6 sm4 md3 lg2 py-4>
                    <img width="100" class="mx-auto" :src="require('@/assets/img/logos/air-new-zealand.svg')" />
                  </v-flex>
                  <v-flex xs6 sm4 md3 lg2 py-4>
                    <img width="100" class="mx-auto" :src="require('@/assets/img/logos/thrifty.svg')" />
                  </v-flex>
                  <v-flex xs6 sm4 md3 lg2 py-4>
                    <img width="100" class="mx-auto" :src="require('@/assets/img/logos/sudima.svg')" />
                  </v-flex>
                  <v-flex xs6 sm4 md3 lg2 py-4>
                    <img width="100" class="mx-auto" :src="require('@/assets/img/logos/the-great-journeys-of-new-zealand.svg')" />
                  </v-flex>
                  <v-flex xs6 sm4 md3 lg2 py-4>
                    <img width="100" class="mx-auto" src="https://cdn.anzcro.com.au/wp-content/uploads/2020/08/voyages.png" />
                  </v-flex>
                  <v-flex xs6 sm4 md3 lg2 py-4>
                    <img width="100" class="mx-auto" src="https://cdn.anzcro.com.au/wp-content/uploads/2020/08/mantra.png" />
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>

    <!--
    <v-layout row class="home-section-cards">
      <v-flex>
        <v-card dark :color="coalBlack" flat tile class="transparent">
          <v-container text-xs-center fill-height>
            <v-layout row align-center>
              <v-flex xs12 sm10 md12 py-5 offset-sm1 offset-md0>
                <h4 class="lightGrey title mb-3">
                  Haven't Got Your
                  <span class="white--text">FREE</span> Account Yet?
                </h4>
                <h3
                  class="white--text display-1 font-weight-bold mb-4"
                >Register today & discover a new World of NZ Travel</h3>
                <v-btn
                  large
                  class="ml-0 mt-4 mb-2 elevation-0"
                  dark
                  :color="kiwiGreen"
                  to="/register/"
                >Register Now</v-btn>
                <v-btn
                  large
                  class="mt-4 mb-2 elevation-0 px-4"
                  color="white"
                  outline
                  to="/login/"
                >Login</v-btn>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>-->
  </v-container>
</template>

<script>
export default {
  data: () => ({
    coalBlack: '#30302F',
    kiwiGreen: '#65b32e',
    length: 2,
    testimonial: 0,
    heros: [
      {
        source:
          'https://cdn.anzcro.com.au/wp-content/uploads/2019/11/hero-rulers-1920x695.png',
        caption: 'Te Puia Maori Experience - Rotorua',
      },
      {
        source:
          'https://cdn.anzcro.com.au/wp-content/uploads/2019/11/hero-rulers-1920x695.png',
        caption: 'Waitomo Glowworm Caves',
      },
      {
        source:
          'https://cdn.anzcro.com.au/wp-content/uploads/2019/11/hero-rulers-1920x695.png',
        caption: 'Ski Holiday - Cardrona Alpine Resort',
      },
      {
        source:
          'https://cdn.anzcro.com.au/wp-content/uploads/2019/11/hero-rulers-1920x695.png',
        caption: 'The Redwoods - Rotorua',
      },
    ],
  }),
  computed: {
    randomHero() {
      return this.heros[Math.floor(Math.random() * this.heros.length)];
    },
  },
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    next() {
      this.testimonial = this.testimonial + 1 === this.length ? 0 : this.testimonial + 1;
    },
    prev() {
      this.testimonial = this.testimonial - 1 < 0 ? this.length - 1 : this.testimonial - 1;
    },
  },
};
</script>

<style>
.home-hero,
.home-product-bg,
.home-cta-bg {
  height: auto;
}

.home-hero {
  position: relative;
}

.home-hero .hero-caption {
  position: absolute;
  display: block;
  background: rgb(48, 48, 47);
  color: #acacac;
  position: absolute;
  bottom: 18px;
  right: 18px;
  padding: 6px 9px;
}

.home-hero .v-image__image{
  background-position: center center !important;
}

.home-hero-cta{
  display:-webkit-box !important;
  -webkit-box-pack:center !important;
  -webkit-box-align:center !important;
}

.home-hero-cta,
.home-hero-cta .fill-height-fix-ios {
  min-height: 82vh;
  min-height: calc(100vh - 200px);
}

.home-hero-cta h1 {
  line-height: 1.2 !important;
}


.home-hero-cta h3{
  max-width:520px;
  line-height: 1.2 !important;
  margin-left:auto;
  margin-right:auto;
}

.hero-card {
  border-radius: 18px;
  margin: 9px 0;
  /*border: solid 1px #222222 !important;*/
}

.home-section-cards {
  background-color: #30302f;
  background-image: url('~@/assets/img/bg_tile_grey_waves_2.png');
  background-repeat: repeat;
  background-size: 50px 51px;
  padding: 16px 0;
}

.container.grid-list-lg .layout .flex.connect-icon-item {
  padding: 8px 24px;
}

.home-section-why {
  background-color: #65b32e;
  background-image: url('~@/assets/img/bg_tile_green_waves.png');
  background-repeat: repeat;
  background-size: 50px 51px;
}

.home-section-cards .v-card.transparent,
.home-section-why .v-card.transparent {
  background-color: transparent !important;
}

.home-section-title {
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 32px;
}

.home-section-title.reservations {
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-bottom: 6px;
}

.home-section-title.green-on-white {
  color: #65b32e;
}

.home-section-title.white-on-green {
  color: #ffffff;
}

.about-quote {
  line-height: 28px !important;
  margin-bottom: 32px;
}

.home-reservations-content {
  padding: 0 24px;
}

.home-reservations-content p {
  margin: 0 0 32px;
}

.product-range-item {
  margin: 32px 0;
}

.headline.hp-testimonial {
  font-size: 16px !important;
  line-height: 1.5 !important;
}

.hero-home-slider .v-carousel__controls {
  background: none;
}

.hero-home-slider img.deal-graphic.holidays-at-home{
  width:270px;
  height: 60px;
}

.hero-home-slider img.deal-graphic.title-320{
  width:270px;
  height: 80px;
}

.hero-home-slider img.deal-graphic.stacked{
  width:240px;
  height: 150px;
}

@media (min-width: 600px) {
  .home-hero {
    height: 100vw;
    max-height:695px;
  }

  .home-hero .v-image__image{
    background-position: center center !important;
  }

  .home-hero-cta,
  .home-hero-cta .fill-height-fix-ios {
    min-height: auto;
  }

  .home-hero-cta .display-1 {
    font-size: 54px !important;
    line-height: 1.2 !important;
  }

  .hero-card {
    margin: 9px;
  }

  .home-section-about p,
  .home-reservations-content p,
  .home-section-why .subheading {
    font-size: 16px;
    line-height: 28px;
  }

  .home-section-title {
    position: relative;
    vertical-align: middle;
  }

  .home-section-title span {
    display: inline-block;
    vertical-align: middle;
    padding: 0 12px;
  }

  .home-section-title.reservations span {
    padding: 0 12px 0 0;
  }

  .home-section-title:before,
  .home-section-title:after {
    content: '';
    display: inline-block;
    width: 36px;
    height: 2px;
    background: #65b32e;
    opacity: 0.75;
  }

  .home-section-title.white-on-green:before,
  .home-section-title.white-on-green:after {
    background: #ffffff;
  }

  .home-section-title.reservations:before {
    display: none;
  }

  .headline.hp-testimonial {
    font-size: 24px !important;
  }

  .container.grid-list-lg .layout .flex.connect-icon-item {
    padding: 24px;
  }

  .connect-headline span {
    display: block;
  }

  .hero-home-slider img.deal-graphic.holidays-at-home{
    width:540px;
    height: 120px;
  }

  .hero-home-slider img.deal-graphic.title-320{
    width:540px;
    height: 160px;
  }

  .hero-home-slider img.deal-graphic.stacked{
    width:320px;
    height: 200px;
    transform: translateX(-12px);
  }
}

@media (min-width: 960px) {

  .home-hero {
    height: 60vw;
    max-height:695px;
  }
  .home-hero .v-image__image {
    background-position: center center !important;
  }
  .home-product-bg {
    max-height: 900px;
  }
  .home-cta-bg {
    max-height: 500px;
  }
  .hero-card {
    margin: 9px 0;
  }
  .about-quote {
    font-size: 32px !important;
    line-height: 44px !important;
  }

  .home-reservations-content {
    padding-left: 48px;
  }

  .home-reservations-content p {
    max-width: 400px;
  }

  .home-section-product .flex.offset-md32 {
    margin-left: 12.5%;
  }

  .product-range-item {
    margin: 40px 0;
  }

  .home-section-about .v-card,
  .home-section-why .v-card,
  .home-section-suppliers .v-card,
  .home-section-testimonials .v-card.card-wrapper {
    padding: 12px 0;
  }

  .hero-home-slider {
    padding: 0;
    position: relative;
  }

  .hero-connect-wrapper .v-card {
    height: 100%;
  }

  .container.grid-list-lg .layout .flex.connect-icon-item {
    padding: 24px 8px;
  }

  .connect-headline span {
    display: inline;
  }

  .home-hero-cta h3{
    max-width:600px;
    margin-left:0;
    margin-right:0;
  }
}

@media (min-width: 1264px) {

  .home-hero {
    height: 40vw;
    max-height:695px;
  }

}

@media (min-width: 1440px) {
  .home-hero-cta .display-1 {
    font-size: 66px !important;
  }
  .home-hero-cta .title {
    font-size: 24px !important;
  }
}
</style>
