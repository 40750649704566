<template>
    <v-container pa-0 fluid>
      <v-layout row>
        <v-flex>
          <v-card flat tile dark class="hero-home-slider">
            <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
              <v-carousel-item>
                <v-img
                  src="https://cdn.anzcro.com.au/wp-content/uploads/2023/03/hero-warm-up-australia-2023-scaled.jpg"
                  gradient="to bottom, rgba(22,22,22,0.65), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.25) 65%"
                  class="home-hero"
                >
                  <v-container fill-height>
                    <v-layout dark column fill-height justify-space-between pt-5>
                      <v-flex text-xs-center class="home-hero-cta">
                        <v-layout row align-center fill-height class="fill-height-fix-ios">
                          <v-flex>
                            <img src="https://cdn.anzcro.com.au/wp-content/uploads/2024/03/warm-up-in-australia-2024-stacked.png" alt="Warm Up in Australia" class="campaign-title stacked" width="480" height="300" />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <div class="hero-caption hidden-xs-only">
                    <small>Hill Inlet, Whitehaven Beach - Photo: Tourism Australia</small>
                  </div>
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-flex>
      </v-layout>
      <div class="gradient-bar"></div>
  
      <v-layout row>
        <v-flex mb-5>
            <v-container grid-list-lg fill-height>
              <v-layout row wrap fill-height>
                <v-flex xs12 py-0 my-0>
                  <v-card tile class="pa-0 elevation-0 deals-content">
                    <v-card-text class="pa-5">
                      <v-layout row wrap>
                        <v-flex xs12 md10 py-0 my-0>
                          <span
                        class="headline font-weight-medium kiwiGreen d-block mb-3"
                      >Ditch the grey skies for tropical paradise with our latest tropical holiday collection.</span>
                          <p>Welcome to the tropical paradise of Australia. Escape to secluded island retreats, discover vibrant coral cays, sink your feet into pure white sand and explore where lush rainforests come to meet pristine waters. Whether you’re looking for a luxurious getaway, trying to escape the hustle and bustle of the city, chasing an outdoor adventure, or taking the family on vacation, this tropical holiday collection will have you swapping skis & snow for sand & sun.</p>
                        </v-flex>
                        <v-flex xs12 md2 py-0 my-0 text-xs-center>
                          <a target="_blank" href="https://issuu.com/holidayexperts/docs/warm_up_e-brochure_2024_nzd?fr=xKAE9_zU1NQ"><img src="https://cdn.anzcro.com.au/wp-content/uploads/2024/03/brochure-cover-warm-up-australia-2024.jpg" alt="Warm Up in Australia eBrochure Cover" class="campaign-sticker kirra-early-bird" width="124" height="175" /></a>
                          <small class="kiwiGreen d-block mt-2" style="line-height:1.2;"><a target="_blank" href="https://issuu.com/holidayexperts/docs/warm_up_e-brochure_2024_nzd?fr=xKAE9_zU1NQ"><b>View eBrochure</b></a></small>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                  <v-card tile dark class="pa-0 ma-0 elevation-0">
                    <v-card-text class="px-3 py-2">
                      <span
                        class="headline font-weight-medium d-block"
                      >{{section.title}}</span>
                    </v-card-text>
                  </v-card>
                  <v-card tile class="px-3 py-3 elevation-0">
                    <v-layout row wrap ma-0>
                      <v-flex xs12 pa-0 hidden-sm-and-down>
                        <v-layout row wrap my-0>
                          <v-flex xs12 md7 lg8>
                            <span class="caption grey--text">Package Deal</span>
                          </v-flex>
                          <v-flex xs12 md3 lg2 text-md-right>
                            <span class="caption grey--text">From Price (NZD)</span>
                          </v-flex>
                          <v-flex xs12 md2 lg2 text-md-center>
                            <span class="caption grey--text">Agent Resources</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                        <v-divider></v-divider>
                        <v-layout row wrap align-center my-3>
                          <v-flex xs12 md7 lg8>
                            <v-layout row align-center>
                              <v-flex shrink hidden-md-and-down>
                                <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                              </v-flex>
                              <v-flex>
                                <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                                <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                                <span class="d-block package-data">
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Start location</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0" v-if="!deal.call">
                                    <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Package Code</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0" v-else>
                                    <b>Call ANZCRO to Book</b><v-icon class="mx-2" size="14" :color="kiwiGreen">phone</v-icon>
                                  </v-chip>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 sm6 md3 lg2 text-md-right>
                            <span class="headline d-block font-weight-medium mb-2 kiwiGreen">${{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                            <span class="caption d-block grey--text">{{deal.complement}}</span>
                          </v-flex>
                          <v-flex xs12 sm6 md2 lg2 text-sm-right text-md-center>
                            <v-tooltip top v-if="deal.sm_tile">
                              <template v-slot:activator="{ on }">
                                <v-btn large v-on="on" icon class="card-with-border elevation-0 mr-1 px-0" color="white" :href="deal.sm_tile" target="_blank"><v-icon :color="kiwiGreen" size="22" class="">image</v-icon></v-btn>
                              </template>
                              <span>View Social Media Tile</span>
                            </v-tooltip>
                            <v-tooltip top v-if="deal.itinerary">
                              <template v-slot:activator="{ on }">
                                <v-btn large v-on="on" icon class="card-with-border elevation-0 mx-0 px-0" :color="kiwiGreen" :href="deal.itinerary" target="_blank"><v-icon color="white" size="22" class="">description</v-icon></v-btn>
                              </template>
                              <span>View eBrochure Deal</span>
                            </v-tooltip>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
        </v-flex>
      </v-layout>
  
  
    <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
    </v-container>
  </template>
  <script>
  export default {
    data: () => ({
      copied: false,
      copiedText: '',
      sections : [
      {
        title : 'From Cairns',
        deals : [
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Frankland-136x136-1.jpg',
            name : '4 Day Cairns & Frankland Islands',
            price : '669',
            complement : 'per person (twin share)',
            code : 'ANZQ244DCFI',
            location : 'Cairns (CNS)',
            valid: '13 Apr - 30 Nov 2024',
            itinerary : 'https://issuu.com/holidayexperts/docs/warm_up_e-brochure_2024_nzd/3?fr=xKAE9_zU1NQ',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Frankland-NZD-Tile.png',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Fitzroy-136x136-1.jpg',
            name : '6 Day Cairns & Fitzroy Island',
            price : '679',
            complement : 'per person (twin share)',
            code : 'ANZQ246DCFI',
            location : 'Cairns (CNS)',
            valid: '01 May - 30 Nov 2024',
            itinerary : 'https://issuu.com/holidayexperts/docs/warm_up_e-brochure_2024_nzd/3?fr=xKAE9_zU1NQ',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Fitzroy-NZD-Tile.png',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Green-Island-136x136-1.jpg',
            name : '5 Day Cairns & Green Island',
            price : '1559',
            complement : 'per person (twin share)',
            code : 'ANZQ245DCGI',
            location : 'Cairns (CNS)',
            valid: '01 May - 30 Nov 2024',
            itinerary : 'https://issuu.com/holidayexperts/docs/warm_up_e-brochure_2024_nzd/4?fr=xKAE9_zU1NQ',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Green-Island-NZD-Tile.png',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Thala-136x136-1.jpg',
            name : '6 Day Cairns & Thala Nature Reserve',
            price : '1928',
            complement : 'per person (twin share)',
            code : 'ANZQ246DNQB',
            location : 'Cairns (CNS)',
            valid: '01 May - 30 Nov 2024',
            itinerary : 'https://issuu.com/holidayexperts/docs/warm_up_e-brochure_2024_nzd/4?fr=xKAE9_zU1NQ',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Thala-NZD-Tile.png',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Classic-Silky-136x136-1.jpg',
            name : '4 Day Classic Silky Escape',
            price : '2839',
            complement : 'per person (twin share)',
            code : 'ANZQ244DSO',
            location : 'Cairns (CNS)',
            valid: '01 May - 30 Nov 2024',
            itinerary : 'https://issuu.com/holidayexperts/docs/warm_up_e-brochure_2024_nzd/5?fr=xKAE9_zU1NQ',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Classic-Silky-NZD-Tile.png',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Family-Green-136x136-1.jpg',
            name : '5 Day Family Cairns & Green Island',
            price : '4735',
            complement : 'per family (2Ad+2Ch)',
            code : 'ANZQ245DCGI',
            location : 'Cairns (CNS)',
            valid: '01 May - 30 Nov 2024',
            itinerary : 'https://issuu.com/holidayexperts/docs/warm_up_e-brochure_2024_nzd/4?fr=xKAE9_zU1NQ',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Family-Green-Island-NZD-Tile.png',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Lux-Beach-Reef-136x136-1.jpg',
            name : '6 Day Luxury Beach & Reef',
            price : '5649',
            complement : 'per person (twin share)',
            code : 'ANZQ246DLBR',
            location : 'Cairns (CNS)',
            valid: '01 May - 30 Nov 2024',
            itinerary : 'https://issuu.com/holidayexperts/docs/warm_up_e-brochure_2024_nzd/5?fr=xKAE9_zU1NQ',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Lux-Beach-Reef-NZD-Tile.png',
            call : false
          },
        ]
      },
      {
        title : 'From Airlie Beach / Hamilton Island',
        deals : [
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Cruise-Whit-136x136-1.jpg',
            name : '7 Day Cruise the Whitsundays',
            price : '1759',
            complement : 'per person (twin share)',
            code : 'ANZQ247DCW',
            location : 'Proserpine (QLD) (PPP)',
            valid: '01 May - 27 Nov 2024',
            itinerary : 'https://issuu.com/holidayexperts/docs/warm_up_e-brochure_2024_nzd/6?fr=xKAE9_zU1NQ',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Cruise-Whits-NZD-Tile.png',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Elysian-136x136-1.jpg',
            name : '5 Day Elysian Eco Retreat',
            price : '3765',
            complement : 'per person (twin share)',
            code : 'ANZQ245DEER',
            location : 'Hamilton Island (HTI)',
            valid: '01 May - 31 Oct 2024',
            itinerary : 'https://issuu.com/holidayexperts/docs/warm_up_e-brochure_2024_nzd/6?fr=xKAE9_zU1NQ',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Elysian-NZD-Tile.png',
            call : false
          },
        ]
      },
      {
        title : 'From Gladstone & Rockhampton',
        deals : [
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Great-Keppel-136x136-1.jpg',
            name : '4 Day Great Keppel Island',
            price : '499',
            complement : 'per person (twin share)',
            code : 'ANZQ4DGKI',
            location : 'Great Keppel Island (QLD) (GTK)',
            valid: '01 May - 29 Sep 2024',
            itinerary : 'https://issuu.com/holidayexperts/docs/warm_up_e-brochure_2024_nzd/7?fr=xKAE9_zU1NQ',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Keppel-NZD-Tile.png',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Heron-136x136-1.jpg',
            name : '4 Day Heron Island',
            price : '1179',
            complement : 'per person (twin share)',
            code : 'ANZQ244DHIE',
            location : 'Gladstone (QLD) (GLT)',
            valid: '01 May - 29 Sep 2024',
            itinerary : 'https://issuu.com/holidayexperts/docs/warm_up_e-brochure_2024_nzd/7?fr=xKAE9_zU1NQ',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Heron-NZD-Tile.png',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Family-Heron-136x136-1.jpg',
            name : '4 Day Family Heron Island',
            price : '2799',
            complement : 'per family (2Ad+2Ch)',
            code : 'ANZQ244DHIE',
            location : 'Gladstone (QLD) (GLT)',
            valid: '01 May - 29 Sep 2024',
            itinerary : 'https://issuu.com/holidayexperts/docs/warm_up_e-brochure_2024_nzd/7?fr=xKAE9_zU1NQ',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Family-Heron-NZD-Tile.png',
            call : false
          },
        ]
      },
      {
        title : 'From Gold Coast',
        deals : [
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/GC-Wine-Dine-136x136-1.jpg',
            name : '6 Day Gold Coast Wine & Dine',
            price : '849',
            complement : 'per person (twin share)',
            code : 'ANZQ246DWD',
            location : 'Gold Coast (OOL)',
            valid: '01 May - 30 Nov 2024',
            itinerary : 'https://issuu.com/holidayexperts/docs/warm_up_e-brochure_2024_nzd/8?fr=xKAE9_zU1NQ',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/GC-Wine-Dine-NZD-Tile.png',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Family-GC-136x136-1.jpg',
            name : '6 Day Family Gold Coast Wildlife & Cruise',
            price : '2059',
            complement : 'per family (2Ad+2Ch)',
            code : 'ANZQ246DGCW',
            location : 'Gold Coast (OOL)',
            valid: '01 May - 30 Nov 2024',
            itinerary : 'https://issuu.com/holidayexperts/docs/warm_up_e-brochure_2024_nzd/8?fr=xKAE9_zU1NQ',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/Family-GC-NZD-Tile.png',
            call : false
          },
        ]
      },
      ]
    }),
    mounted: function() {
      const el = document.scrollingElement || document.documentElement;
      el.scrollTop = 0;
    },
    methods: {
      top() {
        document.documentElement.scrollTop = 0;
      },
      copyCode(event) {
        if(event){
          let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
          window.getSelection().selectAllChildren(copyCode);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyCode.innerHTML);
          this.copiedText = 'Package code copied to clipboard';
          this.copied = true;
        }
      },
      copyLocation(event) {
        if(event){
          let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
          window.getSelection().selectAllChildren(copyLocation);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyLocation.innerHTML);
          this.copiedText = 'Package start location copied to clipboard';
          this.copied = true;
        }
      }
    },
  };
  </script>
  
  <style>
  .gradient-bar{
    width: 100%;
    height: 9px;
    background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
    background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
  }
  
  .deals-content{
    margin-top: -60px;
  }
  
  .package-data .v-chip{
    color:#9e9e9e;
    margin-left: 0;
  }
  
  .package-data .v-chip .v-chip__content{
    padding-right: 6px;
  }
  
  .deal-thumbnail{
    width: 88px;
    height: 88px;
  }
  </style>
  