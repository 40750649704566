<template>
  <v-container pa-0 fluid>
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2021/04/hero-aussie-holidays-nz-3-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <!--<h1 class="white--text display-1 font-weight-bold mb-3">
                            Hot Offers on<br/>Holidays at Home
                          </h1>-->
                          <img src="https://cdn.anzcro.com.au/wp-content/uploads/2021/04/hot-deals-on-aussie-holidays-stacked.png" alt="Hot Deals on Aussie Holidays" class="campaign-title stacked" width="480" height="300" />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Yellow Water Billabong, Kakadu NP - Photo: Tourism NT/James Fisher</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="gradient-bar"></div>

    <v-layout row>
      <v-flex mb-5>
          <v-container grid-list-lg fill-height>
            <v-layout row wrap fill-height>
              <v-flex xs12 py-0 my-0>
                <v-card tile class="pa-0 elevation-0 deals-content">
                  <v-card-text class="pa-5">
                    <span
                      class="headline font-weight-medium kiwiGreen d-block mb-3"
                    >Warm up in Australia with one of these hot holiday offers.</span>
                    <p>Here you’ll find a selection of unique Australian holidays with great value add-ons and prices.</p>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                <v-card tile dark class="pa-0 ma-0 elevation-0">
                  <v-card-text class="px-3 py-2">
                    <span
                      class="headline font-weight-medium d-block"
                    >{{section.title}}</span>
                  </v-card-text>
                </v-card>
                <v-card tile class="px-3 py-3 elevation-0">
                  <v-layout row wrap ma-0>
                    <v-flex xs12 pa-0 hidden-sm-and-down>
                      <v-layout row wrap my-0>
                        <v-flex xs12 md7 lg8>
                          <span class="caption grey--text">Package Deal</span>
                        </v-flex>
                        <v-flex xs12 md3 lg2 text-md-right>
                          <span class="caption grey--text">From Price (NZD)</span>
                        </v-flex>
                        <v-flex xs12 md2 lg2 text-md-right>
                          <span class="caption grey--text">Inclusions & Terms</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                      <v-divider></v-divider>
                      <v-layout row wrap align-center my-3>
                        <v-flex xs12 md7 lg8>
                          <v-layout row align-center>
                            <v-flex shrink hidden-md-and-down>
                              <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                            </v-flex>
                            <v-flex>
                              <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                              <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                              <span class="d-block package-data">
                                <v-chip label color="#f5f5f5" disabled class="mb-0">
                                  <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                  <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                      </template>
                                      <span>Copy Start location</span>
                                  </v-tooltip>
                                </v-chip>
                                <v-chip label color="#f5f5f5" disabled class="mb-0">
                                  <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                  <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                      </template>
                                      <span>Copy Package Code</span>
                                  </v-tooltip>
                                </v-chip>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm6 md3 lg2 text-md-right>
                          <span class="headline d-block font-weight-medium mb-2 kiwiGreen">${{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                          <span class="caption d-block grey--text">{{deal.complement}}</span>
                        </v-flex>
                        <v-flex xs12 sm6 md2 lg2 text-sm-right>
                          <v-btn large class="card-with-border elevation-0 mx-0 px-2" :color="lightGrey" :href="deal.itinerary" target="_blank"><v-icon color="grey" size="18" class="mr-1">launch</v-icon> View</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
      </v-flex>
    </v-layout>


  <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    copied: false,
    copiedText: '',
    sections : [
      {
        title : 'Deals',
        deals : [/*
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/04/deal-thumbnail-aussie-holidays-1.jpg',
            name : '4 Days Discover Tropical Cairns',
            price : '559',
            complement : 'per person (twin share)',
            code : 'ANZAU214DDTC',
            location : 'Cairns (CNS)',
            valid: '01 Apr 2021 - 30 Nov 2021',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/04/Discover-Tropical-Cairns.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/04/deal-thumbnail-aussie-holidays-2.jpg',
            name : '4 Days Top End Explorer',
            price : '645',
            complement : 'per person (twin share)',
            code : 'ANZAU214DTEE',
            location : 'Darwin (DRW)',
            valid: '01 May 2021 - 30 Nov 2021',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/04/Top-End-Explorer.pdf'
          },*/
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/06/deal-thumbnail-aussie-holidays-10.jpg',
            name : '3 Days Luxury in the Barossa',
            price : '775',
            complement : 'per person (twin share)',
            code : 'ANZAU213DLLE',
            location : 'Barossa Valley (BAR)',
            valid: '01 Sep 2021 - 31 Mar 2022',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/06/The-Louise-flyer.pdf'
          },/*
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/04/deal-thumbnail-aussie-holidays-3.jpg',
            name : '4 Days City to Hinterland Retreat',
            price : '895',
            complement : 'per person (twin share)',
            code : 'ANZAU214DCHS',
            location : 'Brisbane (BNE)',
            valid: '16 Apr 2021 - 30 Nov 2021',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/04/City-to-Hinterland-Retreat.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/04/deal-thumbnail-aussie-holidays-4.jpg',
            name : '4 Days Heli Fishing Adventure',
            price : '1199',
            complement : 'per person (twin share)',
            code : 'ANZAU214DHFA',
            location : 'Darwin (DRW)',
            valid: '01 May 2021 - 30 Nov 2021',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/04/Top-End-Heli-Fishing-Adventure.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/04/deal-thumbnail-aussie-holidays-5.jpg',
            name : '5 Days Original Arkaba Walk',
            price : '3169',
            complement : 'per person (twin share)',
            code : 'ANZAUPB21225DOAW',
            location : 'Adelaide (ADL)',
            valid: '01 May 2021 - 14 Oct 2021',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/04/Original-Arkaba-Walk.pdf'
          },*/
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/06/deal-thumbnail-aussie-holidays-11.jpg',
            name : '5 Days Arkaba Luxury Experience',
            price : '3499',
            complement : 'per person (twin share)',
            code : 'ANZAU215DALE',
            location : 'Flinders Ranges National Park',
            valid: '01 Dec 2021 - 28 Feb 2022',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/06/Arkaba-Luxury-Experience-Stay-4-Pay-3-flyer.pdf'
          },/*
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/04/deal-thumbnail-aussie-holidays-6.jpg',
            name : '7 Days Venture to the Top End',
            price : '4089',
            complement : 'per person (twin share)',
            code : 'ANZAU217DVTE',
            location : 'Darwin (DRW)',
            valid: '01 May 2021 - 31 Oct 2021',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/04/Venture-to-the-Top-End.pdf'
          },*/
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/05/deal-thumbnail-aussie-holidays-9.jpg',
            name : '8 Days Outback Rail Journey',
            price : '4175',
            complement : 'per person (twin share)',
            code : ' ANZAU218DORJ-S',
            location : 'Darwin (DRW)',
            valid: '31 Oct 2021 - 31 Mar 2022',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/04/Outback-Rail-Journey-Southbound.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/05/deal-thumbnail-aussie-holidays-8.jpg',
            name : '9 Days Coast to Coast Rail Journey',
            price : '4055',
            complement : 'per person (twin share)',
            code : 'ANZAU219DCTCR-W',
            location : 'Sydney (SYD)',
            valid: '01 Feb 2022 - 31 Jul 2022',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/04/Coast-to-Coast-Rail-Journey-Flyer.pdf'
          },/*
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/05/deal-thumbnail-aussie-holidays-7.jpg',
            name : '11 Days Southern Kimberley Cruise',
            price : '14885',
            complement : 'per person (twin share)',
            code : 'ANZAU2111DSKC',
            location : 'Broome (BME)',
            valid: '14 Aug 2022',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/05/11-Days-Southern-Kimberley-Cruise-Flyer.pdf'
          },*/
        ]
      },
    ]
  }),
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
    copyCode(event) {
      if(event){
        let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
        window.getSelection().selectAllChildren(copyCode);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyCode.innerHTML);
        this.copiedText = 'Package code copied to clipboard';
        this.copied = true;
      }
    },
    copyLocation(event) {
      if(event){
        let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
        window.getSelection().selectAllChildren(copyLocation);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyLocation.innerHTML);
        this.copiedText = 'Package start location copied to clipboard';
        this.copied = true;
      }
    }
  },
};
</script>

<style>
.gradient-bar{
  width: 100%;
  height: 9px;
  background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
  background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
}

.deals-content{
  margin-top: -60px;
}

.package-data .v-chip{
  color:#9e9e9e;
  margin-left: 0;
}

.package-data .v-chip .v-chip__content{
  padding-right: 6px;
}

.deal-thumbnail{
  width: 88px;
  height: 88px;
}
</style>
