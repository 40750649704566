<template>
    <v-container pa-0 fluid>
      <v-layout row class="hero-waves">
        <v-flex>
          <v-container fill-height>
            <v-layout dark row wrap fill-height align-center pt-5>
              <v-flex xs12 text-xs-center text-md-left class="hero-title">
                <h1 class="white--text display-1 font-weight-bold mb-3">
                  Thank you for contacting<br/>the ANZCRO Team
                </h1>
              </v-flex>
            </v-layout>
          </v-container>
        </v-flex>
      </v-layout>
  
      <v-layout row>
        <v-flex>
          <v-card flat tile :color="offWhite">
            <v-container grid-list-lg fill-height>
              <v-layout row wrap fill-height style="margin-top:-48px;">
                <v-flex xs12 pt-0>
                  <v-card flat class="pa-5 dash-rounded">
                    <p class="title mb-4">One of our New Zealand holiday experts will be in touch very shortly to assist with your enquiry.</p>
                    <p class="subheading">Alternatively, feel free to call us on 0800 269276, or <a href="/register/">register</a> and <a href="/login/">log in</a> to ANZCRO Connect where we are ready to help via the live chat function.</p>
                    <p class="title">Have a good day!</p>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </template>
  
  <script>
  export default {
    data: () => ({
      mydata: 1
    }),
    mounted: function() {
      const el = document.scrollingElement || document.documentElement;
      el.scrollTop = 0;
    },
    methods: {
      top() {
        document.documentElement.scrollTop = 0;
      },
    },
  };
  </script>
  
  <style>
  </style>
  