<template>
  <v-container pa-0 fluid>
    <v-layout row class="hero-waves">
      <v-flex>
        <v-container fill-height>
          <v-layout dark row wrap fill-height align-center pt-5>
            <v-flex xs12 text-xs-center text-md-left class="hero-title">
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2020/01/anzcro-connect-inverted.svg"
                width="220"
                class="my-2 d-inline-block"
              ></v-img>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <v-layout row class="registration-main">
      <v-flex>
        <v-card flat tile>
          <v-container grid-list-lg fill-height>
            <v-layout row wrap fill-height>
              <v-flex xs12 md8 pt-0 mb-4 mt-3>
                <v-card class="pa-0 elevation-0 dash-rounded">
                  <v-card-text class="pa-4">
                    <span
                      class="headline font-weight-medium kiwiGreen d-block mb-3"
                    >Register for ANZCRO Connect</span>
                    <span class="subheading d-block">
                      If your agency is already registered with ANZCRO but you don't have a login for ANZCRO Connect yet, simply fill the form below to request an
                      account.
                    </span>
                  </v-card-text>
                  <v-divider class="mx-3 my-3"></v-divider>
                  <v-alert :value="true" type="error" v-if="errorMsg.length > 0">{{errorMsg}}</v-alert>
                  <v-form v-model="valid">
                    <v-stepper v-model="registerStepper" vertical class="elevation-0">
                      <v-stepper-step :complete="registerStepper > 1" step="1" editable>
                        <span class="subheading d-block mb-1 font-weight-medium">Agency Details</span>
                        <small class="font-weight-medium">Enter your agency details below.</small>
                      </v-stepper-step>

                      <v-stepper-content step="1">
                        <v-form ref="detailForm">
                          <v-card flat color="transparent" class="mb-4">
                            <v-layout row wrap>
                              <v-flex xs12 sm6 pb-1>
                                <v-text-field
                                  tabindex="1"
                                  v-model="formData.agencyname"
                                  :rules="agencyNameRules"
                                  box
                                  label="Agency Name (required)"
                                  required
                                  :color="kiwiGreen"
                                ></v-text-field>
                              </v-flex>

                              <v-flex xs12 mt-3>
                                <span class="subheading font-weight-medium">Contact</span>
                              </v-flex>

                              <v-flex xs12 pb-1>
                                <v-text-field
                                  tabindex="4"
                                  v-model="formData.emailaddress"
                                  :rules="agencyEmailRules"
                                  box
                                  label="Agency Email (required)"
                                  required
                                  type="email"
                                  :color="kiwiGreen"
                                ></v-text-field>
                              </v-flex>

                              <v-flex xs12 sm6 pb-1>
                                <v-text-field
                                  tabindex="5"
                                  v-model="formData.phone"
                                  :rules="agencyPhoneRules"
                                  box
                                  label="Phone (required)"
                                  required
                                  :color="kiwiGreen"
                                ></v-text-field>
                              </v-flex>

                              <v-flex xs12 mt-3>
                                <span class="subheading font-weight-medium">Address</span>
                              </v-flex>

                              <v-flex xs12 pb-1>
                                <v-text-field
                                  tabindex="7"
                                  v-model="formData.address"
                                  :rules="agencyStreetAddressRules"
                                  box
                                  label="Street Address (required)"
                                  :color="kiwiGreen"
                                ></v-text-field>
                              </v-flex>

                              <v-flex xs12 sm6 pb-1>
                                <v-text-field
                                  tabindex="8"
                                  v-model="formData.city"
                                  :rules="agencyCitySuburbRules"
                                  box
                                  label="City / Suburb (required)"
                                  :color="kiwiGreen"
                                ></v-text-field>
                              </v-flex>

                              <v-flex xs12 sm6 pb-1>
                                <v-text-field
                                  tabindex="9"
                                  v-model="formData.state"
                                  box
                                  label="State / Region"
                                  :color="kiwiGreen"
                                ></v-text-field>
                              </v-flex>

                              <v-flex xs12 sm6 pb-1>
                                <v-text-field
                                  tabindex="10"
                                  v-model="formData.postcode"
                                  box
                                  label="Postcode"
                                  :color="kiwiGreen"
                                ></v-text-field>
                              </v-flex>

                              <v-flex xs12 sm6 pb-1>
                                <v-autocomplete
                                  tabindex="11"
                                  v-model="formData.countryid"
                                  :items="countries"
                                  item-text="name"
                                  item-value="countryid"
                                  :rules="[v => !!v || 'Country is required']"
                                  box
                                  clearable
                                  label="Country (required)"
                                ></v-autocomplete>
                              </v-flex>
                            </v-layout>
                          </v-card>
                          <v-btn
                            large
                            color="primary"
                            @click="gotoAccountManager()"
                            class="mx-0 elevation-0 card-with-border"
                          >Continue</v-btn>
                        </v-form>
                      </v-stepper-content>

                      <v-stepper-step step="2">
                        <span class="subheading d-block mb-1 font-weight-bold">Your Details</span>
                        <small class="font-weight-medium">Travel Agent</small>
                      </v-stepper-step>

                      <v-stepper-content step="2">
                        <v-form ref="managerForm" v-if="registerStepper==2">
                          <v-card flat color="transparent" class="mb-4">
                            <v-layout row wrap>
                              <v-flex xs12 sm6 pb-1>
                                <v-text-field
                                  v-model="formData.firstname"
                                  :rules="firstNameRules"
                                  box
                                  label="First Name"
                                  required
                                  :color="kiwiGreen"
                                ></v-text-field>
                              </v-flex>

                              <v-flex xs12 sm6 pb-1>
                                <v-text-field
                                  v-model="formData.lastname"
                                  :rules="lastNameRules"
                                  box
                                  label="Last Name"
                                  required
                                  :color="kiwiGreen"
                                ></v-text-field>
                              </v-flex>

                              <v-flex xs12 sm6 pb-1>
                                <v-text-field
                                  v-model="formData.username"
                                  :rules="emailRules"
                                  box
                                  label="Email"
                                  required
                                ></v-text-field>
                              </v-flex>

                              <v-flex xs12 sm6 pb-1>
                                <v-text-field
                                  v-model="confirmEmail"
                                  :rules="emailConfirmationRules"
                                  box
                                  label="Confirm Email"
                                  required
                                  :color="kiwiGreen"
                                ></v-text-field>
                              </v-flex>

                              <v-flex xs12 pt-0>
                                <v-checkbox
                                  v-model="agreed"
                                  :rules="[v => v || 'You haven\'t agreed to the Terms of Use']"
                                  :color="kiwiGreen"
                                >
                                  <template v-slot:label>
                                    Tick here to indicate that you have read and agree to the&nbsp;
                                    <v-tooltip bottom>
                                      <template v-slot:activator="{ on }">
                                        <a
                                          target="_blank"
                                          href="/website-terms/"
                                          @click.stop
                                          v-on="on"
                                        >Terms of Use</a>
                                      </template>
                                      View Terms of Use
                                    </v-tooltip>
                                  </template>
                                </v-checkbox>
                              </v-flex>
                            </v-layout>
                          </v-card>
                          <v-btn
                            large
                            color="primary"
                            :loading="loading"
                            @click="submit()"
                            class="ml-0 elevation-0 card-with-border"
                          >Request Agent Login</v-btn>
                        </v-form>
                      </v-stepper-content>
                    </v-stepper>
                  </v-form>
                </v-card>
              </v-flex>
              <v-flex xs12 md4 mt-3>
                <v-card flat class="pa-0 elevation-0 dash-rounded mb-4 card-with-border">
                  <v-card-text class="pa-4">
                    <span class="headline font-weight-medium kiwiGreen d-block mb-3">New Agency</span>
                    <span
                      class="subheading d-block"
                    >If you have not worked with ANZCRO before please register your agency.</span>
                  </v-card-text>
                  <v-divider class="mx-3 mt-2"></v-divider>
                  <v-card-text class="pa-4">
                    <v-btn
                      dark
                      large
                      :color="headerGrey"
                      to="/new-agency-registration/"
                      class="ma-0 elevation-0 card-with-border px-4"
                    >Register Your Agency</v-btn>
                  </v-card-text>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import { registerMixin } from './register.mixin'
export default {
  mixins: [registerMixin],
  data: () => ({
    wholesalerId: 2
  }),
  mounted() {
    this.formData.countryid = 93;
  }
};
</script>
