<template>
  <v-container pa-0 fluid>
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2022/04/hero-deals-new-zealand-holidays-at-home-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.2) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <!--<h1 class="white--text display-1 font-weight-bold mb-3">
                            Hot Offers on<br/>Holidays at Home
                          </h1>-->
                          <img src="https://cdn.anzcro.com.au/wp-content/uploads/2022/04/new-zealand-holidays-at-home-stacked.png" alt="New Zealand Holidays at Home" class="campaign-title stacked" width="480" height="300" />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Milford Sound, Southland - Credit: Great South</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="gradient-bar"></div>

    <v-layout row>
      <v-flex mb-5>
          <v-container grid-list-lg fill-height>
            <v-layout row wrap fill-height>
              <v-flex xs12 py-0 my-0>
                <v-card tile class="pa-0 elevation-0 deals-content">
                  <v-card-text class="pa-5">
                    <span
                      class="headline font-weight-medium kiwiGreen d-block mb-3"
                    >Looking for a holiday at home this year?</span>
                    <p>Holiday at home and explore the natural wonders of Aotearoa. From the iconic sights to the hidden delights, you will find a collection of carefully curated packages to showcase the striking landscape of New Zealand.</p>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                <v-card tile dark class="pa-0 ma-0 elevation-0">
                  <v-card-text class="px-3 py-2">
                    <span
                      class="headline font-weight-medium d-block"
                    >{{section.title}}</span>
                  </v-card-text>
                </v-card>
                <v-card tile class="px-3 py-3 elevation-0">
                  <v-layout row wrap ma-0>
                    <v-flex xs12 pa-0 hidden-sm-and-down>
                      <v-layout row wrap my-0>
                        <v-flex xs12 md7 lg8>
                          <span class="caption grey--text">Package Deal</span>
                        </v-flex>
                        <v-flex xs12 md3 lg2 text-md-right>
                          <span class="caption grey--text">From Price (NZD)</span>
                        </v-flex>
                        <v-flex xs12 md2 lg2 text-md-right>
                          <span class="caption grey--text">Inclusions & Terms</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                      <v-divider></v-divider>
                      <v-layout row wrap align-center my-3>
                        <v-flex xs12 md7 lg8>
                          <v-layout row align-center>
                            <v-flex shrink hidden-md-and-down>
                              <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                            </v-flex>
                            <v-flex>
                              <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                              <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                              <span class="d-block package-data">
                                <v-chip label color="#f5f5f5" disabled class="mb-0">
                                  <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                  <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                      </template>
                                      <span>Copy Start location</span>
                                  </v-tooltip>
                                </v-chip>
                                <v-chip label color="#f5f5f5" disabled class="mb-0">
                                  <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                  <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                      </template>
                                      <span>Copy Package Code</span>
                                  </v-tooltip>
                                </v-chip>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm6 md3 lg2 text-md-right>
                          <span class="headline d-block font-weight-medium mb-2 kiwiGreen">${{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                          <span class="caption d-block grey--text">{{deal.complement}}</span>
                        </v-flex>
                        <v-flex xs12 sm6 md2 lg2 text-sm-right>
                          <v-btn large class="card-with-border elevation-0 mx-0 px-2" :color="lightGrey" :href="deal.itinerary" target="_blank"><v-icon color="grey" size="18" class="mr-1">launch</v-icon> View</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
      </v-flex>
    </v-layout>


  <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    copied: false,
    copiedText: '',
    sections : [
      {
        title : 'Deals',
        deals : [
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/deal-gardens-of-north-island.jpg',
            name : '8 Days Gardens of North Island',
            price : '1349',
            complement : 'per person (twin share)',
            code : 'ANZ237NGONI',
            location : 'Auckland (AKL)',
            valid: '01 Sep 2023 - 31 Mar 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/Gardens-of-North-Island-NZD-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/03/deal-thumbnail-all-time-thermal.jpg',
            name : '8 Days Thermal Explorer',
            price : '1599',
            complement : 'per person (twin share)',
            code : 'NZB238DTE',
            location : 'Auckland (AKL)',
            valid: '01 Sep 2023 - 31 Mar 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/Thermal-Explorer-NZD-Flyer-1.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/deal-ni-fnw.jpg',
            name : '9 Days North Island Food & Wine',
            price : '1879',
            complement : 'per person (twin share)',
            code : 'ANZ239DNIFW',
            location : 'Auckland (AKL)',
            valid: '01 Sep 2023 - 31 Mar 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/North-Island-Food-Wine-NZD-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/del-southern-tracks.jpg',
            name : '10 Day Southern Tracks',
            price : '1899',
            complement : 'per person (twin share)',
            code : 'ANZ2310DST',
            location : 'Christchurch (CHC)',
            valid: '01 Oct 2023 - 31 Mar 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/Southern-Tracks-NZD-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/deal-13d-nd.jpg',
            name : '13 Days Northern Discovery',
            price : '2499',
            complement : 'per person (twin share)',
            code : 'ANZ2313DND',
            location : 'Auckland (AKL)',
            valid: '01 Sep 2023 - 31 Mar 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/Northern-Discovery-NZD-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-northern-explorer.jpg',
            name : '10 Day Northern Explorer',
            price : '2669',
            complement : 'per person (twin share)',
            code : 'ANZ2310DNE',
            location : 'Auckland (AKL)',
            valid: '01 Oct 2023 - 30 Apr 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/Northern-Explorer-NZD-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/deal-si-leisure.jpg',
            name : '14 Day South Island at Leisure',
            price : '2689',
            complement : 'per person (twin share)',
            code : 'ANZ2314DSIAL',
            location : 'Christchurch (CHC)',
            valid: '01 Oct 2023 - 31 Mar 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/South-Island-at-Leisure-NZD-Flyer.pdf'
          },
          {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-sojourn.jpg',
              name : '14 Days Southern Sojourn',
              price : '2869',
              complement : 'per person (twin share)',
              code : 'ANZ2314DSS',
              location : 'Christchurch (CHC)',
              valid: '01 Oct 2023 - 31 Mar 2024',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/Southern-Sojourn-NZD-Flyer.pdf'
          },
          {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/03/deal-thumbnail-all-time-best-of-both.jpg',
              name : '22 Days Best of Both Islands',
              price : '4799',
              complement : 'per person (twin share)',
              code : 'NZB2322BOBI',
              location : 'Auckland (AKL)',
              valid: '01 Oct 2023 - 30 Apr 2024',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/Best-of-Both-Islands-NZD-Flyer.pdf'
          },
          /*
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-spring-ski-2.jpg',
            name : '7 Nights Wanaka Spring Ski',
            price : '1449',
            complement : 'per person (twin share)',
            code : 'ANZ237WSSKI',
            location : 'Queenstown (ZQN)',
            valid: '01 Sep 2023 - 15 Oct 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/3-Deals-Spring-Ski-NZD-PDF.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/04/deal-ultimate-rail-nz.jpg',
            name : '10 Day NZ Rail Explorer',
            price : '2750',
            complement : 'per person (twin share)',
            code : 'ANZ2410DRE',
            location : 'Queenstown (ZQN)',
            valid: 'Jan - Mar 2024 (select start dates)',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/04/10-Day-NZ-Rail-Explorer-NZD-2024.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-spring-ski-3.jpg',
            name : '5 Nights Queenstown Family Spring Ski',
            price : '4290',
            complement : 'per family (2Ad+2Ch)',
            code : 'ANZ235FSS',
            location : 'Queenstown (ZQN)',
            valid: '01 Sep 2023 - 15 Oct 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/3-Deals-Spring-Ski-NZD-PDF.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-marlborough-sunset.jpg',
            name : '5 Day Rail & Sounds',
            price : '739',
            complement : 'per person (twin share)',
            code : 'ANZ235DRS',
            location : 'Christchurch (CHC)',
            valid: '01 Oct 2023 - 31 Mar 2024 (rail departs Thu-Sun only)',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/Coastal-Pacific-Rail-Stay-NZD-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-kaikoura-whale.jpg',
            name : '5 Day Rail & Whales',
            price : '759',
            complement : 'per person (twin share)',
            code : 'ANZ235DRW',
            location : 'Christchurch (CHC)',
            valid: '01 Oct 2023 - 31 Mar 2024 (rail departs Thu-Sun only)',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/Coastal-Pacific-Rail-Stay-NZD-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/deal-spring-ski-1.jpg',
            name : '7 Nights Queenstown Spring Ski',
            price : '1289',
            complement : 'per person (twin share)',
            code : 'ANZ237SPRINGQ',
            location : 'Queenstown (ZQN)',
            valid: '01 Sep 2023 - 15 Oct 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/07/3-Deals-Spring-Ski-NZD-PDF.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-marlborough-vines.jpg',
            name : '5 Day Rail, Wine & Dine',
            price : '1339',
            complement : 'per person (twin share)',
            code : 'ANZ235DRWD',
            location : 'Christchurch (CHC)',
            valid: '01 Oct 2023 - 31 Mar 2024 (rail departs Thu-Sun only)',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/Coastal-Pacific-Rail-Stay-NZD-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/deal-rail-luxury.jpg',
            name : '12 Day Grand Rail Explorer Luxury',
            price : '3599',
            complement : 'per person (twin share)',
            code : 'ANZ2312DGRE-LUX',
            location : 'Queenstown (ZQN)',
            valid: 'Apr - Sep 2023 (select start dates)',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/11/Grand-Rail-Explorer-Luxury-NZD-20221129.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/04/deal-thumbnail-fiordland-lodges.jpg',
            name : '4 Nights Iconic Lodges of Fiordland',
            price : '2039',
            complement : 'per person (double share)',
            code : 'ANZ234NILOF',
            location : 'Queenstown Airport (ZQNA)',
            valid: '01 Apr - 30 Sep 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/ANZ234NILOF-Iconic-Lodges-of-Fiordland-NZD-MAR23.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/04/deal-thumbnail-purepod-2.jpg',
            name : '4 Nights Ultimate PurePod Experience',
            price : '1875',
            complement : 'per person (twin share)',
            code : 'ANZ234NUPPE',
            location : 'Christchurch (CHC)',
            valid: '01 Aug - 30 Sep 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/ANZ234NUPPE-Ultimate-Purepod-Experience-NZD-MAR23.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/SM-CHC-FEB-23-2-150x150.jpg',
            name : '3 Night Luxury City & Rail',
            price : '759',
            complement : 'per person (twin share)',
            code : 'CHC233NLCR',
            location : 'Christchurch (CHC)',
            valid: '01 Apr - 30 Sep 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/3-Deals-Autumn-in-Christchurch-NZD.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/SM-CHC-FEB-23-3-150x150.jpg',
            name : '5 Night Christchurch City & Wildlife',
            price : '1179',
            complement : 'per person (twin share)',
            code : 'CHC235NCCW',
            location : 'Christchurch (CHC)',
            valid: '01 Apr - 30 Sep 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/3-Deals-Autumn-in-Christchurch-NZD.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/02/SM-CHC-FEB-23-1-150x150.jpg',
            name : '4 Night Christchurch Autumn Getaway',
            price : '579',
            complement : 'per person (twin share)',
            code : 'CHC234NCAG',
            location : 'Christchurch (CHC)',
            valid: '01 Apr - 30 Jun 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/03/3-Deals-Autumn-in-Christchurch-NZD.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/12/deal-thumbnail-nzd-purepod-1.jpg',
            name : '2 Nights Greystone PurePod & Winery',
            price : '959',
            complement : 'per person (twin share)',
            code : 'ANZ222NGRE-POD',
            location : 'Waipara (NZ) (WPR)',
            valid: 'Apr 2022 - Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/PurePod-Perfection-NZD.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/12/deal-thumbnail-nzd-purepod-2.jpg',
            name : '2 Nights Kahutara PurePod & Whales',
            price : '999',
            complement : 'per person (twin share)',
            code : 'ANZ222NKAH-POD',
            location : 'Kaikoura (KAI)',
            valid: 'Apr 2022 - Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/PurePod-Perfection-NZD.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/12/deal-thumbnail-nzd-purepod-3.jpg',
            name : '2 Nights Pohue PurePod & Dolphins',
            price : '999',
            complement : 'per person (twin share)',
            code : 'ANZ222NPOH-POD',
            location : 'Christchurch (CHC)',
            valid: 'Apr 2022 - Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/PurePod-Perfection-NZD.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/12/deal-thumbnail-nzd-purepod-4.jpg',
            name : '2 Nights Manakau PurePod & Heli Picnic',
            price : '1279',
            complement : 'per person (twin share)',
            code : 'ANZ222NMAN-POD',
            location : 'Kaikoura (KAI)',
            valid: 'Apr 2022 - Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/PurePod-Perfection-NZD.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/deal-ski-spring-mt-hutt.jpg',
            name : '6 Nights Mt Hutt Spring Ski Special',
            price : '965',
            complement : 'per person (twin share)',
            code : 'ANZ226NMSS',
            location : 'Christchurch (CHC)',
            valid: '01 Sep 2022 - 16 Oct 2022',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/Spring-Ski-Special-NZD-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/deal-ski-spring-zqn.jpg',
            name : '5 Nights Queenstown Spring Ski Special',
            price : '885',
            complement : 'per person (twin share)',
            code : 'ANZ225NQSS',
            location : 'Queenstown (ZQN)',
            valid: '01 Sep 2022 - 16 Oct 2022',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/Spring-Ski-Special-NZD-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/04/deal-thumbnail-hobbiton.jpg',
            name : '3 Days Hobbiton & Homestead',
            price : '450',
            complement : 'per person (twin share)',
            code : 'ANZ213DHH',
            location : 'Matamata (MTA)',
            valid: '01 Apr 2022 - 31 Oct 2022',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/Hobbiton-Homestead-NZD-Flyer.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/05/deal-real-fiordland.jpg',
            name : '2 Nights Real Fiordland',
            price : '499',
            complement : 'per person (twin share)',
            code : 'ANZ212NRF',
            location : 'Te Anau (TEU)',
            valid: '10 May 2022 - 30 Sep 2022',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/05/2-Night-Real-Fiordland-NZD-Flyer.pdf'
          },
          */
        ]
      },
    ]
  }),
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
    copyCode(event) {
      if(event){
        let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
        window.getSelection().selectAllChildren(copyCode);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyCode.innerHTML);
        this.copiedText = 'Package code copied to clipboard';
        this.copied = true;
      }
    },
    copyLocation(event) {
      if(event){
        let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
        window.getSelection().selectAllChildren(copyLocation);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyLocation.innerHTML);
        this.copiedText = 'Package start location copied to clipboard';
        this.copied = true;
      }
    }
  },
};
</script>

<style>
.gradient-bar{
  width: 100%;
  height: 9px;
  background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
  background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
}

.deals-content{
  margin-top: -60px;
}

.package-data .v-chip{
  color:#9e9e9e;
  margin-left: 0;
}

.package-data .v-chip .v-chip__content{
  padding-right: 6px;
}

.deal-thumbnail{
  width: 88px;
  height: 88px;
}
</style>
