<template>
    <v-container pa-0 fluid>
      <v-layout row>
        <v-flex>
          <v-card flat tile dark class="hero-home-slider">
            <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
              <v-carousel-item>
                <v-img
                  src="https://cdn.anzcro.com.au/wp-content/uploads/2023/10/ult-lux-holidays-new-zealand_banner-3840x1390-1-scaled.jpg"
                  gradient="to bottom, rgba(22,22,22,0.65), rgba(22,22,22,.55) 15%, rgba(22,22,22,0.35) 65%"
                  class="home-hero"
                >
                  <v-container fill-height>
                    <v-layout dark column fill-height justify-space-between pt-5>
                      <v-flex text-xs-center class="home-hero-cta">
                        <v-layout row align-center fill-height class="fill-height-fix-ios">
                          <v-flex>
                            <img src="https://cdn.anzcro.com.au/wp-content/uploads/2023/10/ult-lux-holidays-new-zealand_graphic-960x600-1.png" alt="Ultimate Luxury Holidays New Zealand" class="campaign-title stacked" width="480" height="300" />
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-container>
                  <div class="hero-caption hidden-xs-only">
                    <small>Annandale Coast Farm & Luxury Villas</small>
                  </div>
                </v-img>
              </v-carousel-item>
            </v-carousel>
          </v-card>
        </v-flex>
      </v-layout>
      <div class="gradient-bar"></div>
  
      <v-layout row>
        <v-flex mb-5>
            <v-container grid-list-lg fill-height>
              <v-layout row wrap fill-height>
                <v-flex xs12 py-0 my-0>
                  <v-card tile class="pa-0 elevation-0 deals-content">
                    <v-card-text class="pa-5">
                      <v-layout row wrap>
                        <v-flex xs12 md10 py-0 my-0>
                            <span class="headline font-weight-medium kiwiGreen d-block mb-3">Experience exquisite luxury in the most remarkable landscapes.</span>
                            <p>
                              Here in Aotearoa New Zealand, the most exquisite luxury lodges are strategically situated to highlight our country's remarkable landscapes. You can expect unmatched comfort, extraordinary hospitality, and customised activities that cater to individual preferences within this choice of six opulent New Zealand journeys. Here, you'll encounter rugged beauty, paired with elevated sightseeing opportunities, crafting an unforgettable experience.
                            </p>
                        </v-flex>
                        <v-flex xs12 md2 py-0 my-0 text-xs-center>
                          <a 
                            target="_blank" 
                            href="https://issuu.com/holidayexperts/docs/issuu_anzcro_luxury_23-24_nzd?fr=xKAE9_zU1NQ"
                            >
                                <img 
                                    src="https://cdn.anzcro.com.au/wp-content/uploads/2023/10/Ultimate-Luxury-Holidays-brochure-cover-248x248-1.jpg" 
                                    alt="Ultimate Luxury Holidays New Zealand  eBrochure Cover" 
                                    class="campaign-sticker kirra-early-bird" 
                                    width="124" 
                                    height="124" />
                            </a>
                            <small class="kiwiGreen d-block mt-2" style="line-height:1.2;">
                                <a 
                                    target="_blank" 
                                    href="https://issuu.com/holidayexperts/docs/issuu_anzcro_luxury_23-24_nzd?fr=xKAE9_zU1NQ">
                                    <b>View eBrochure</b>
                                </a>
                            </small>
                        </v-flex>
                      </v-layout>
                    </v-card-text>
                  </v-card>
                </v-flex>
                <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                  <v-card tile dark class="pa-0 ma-0 elevation-0">
                    <v-card-text class="px-3 py-2">
                      <span
                        class="headline font-weight-medium d-block"
                      >{{section.title}}</span>
                    </v-card-text>
                  </v-card>
                  <v-card tile class="px-3 py-3 elevation-0">
                    <v-layout row wrap ma-0>
                      <v-flex xs12 pa-0 hidden-sm-and-down>
                        <v-layout row wrap my-0>
                          <v-flex xs12 md7 lg8>
                            <span class="caption grey--text">Package Deal</span>
                          </v-flex>
                          <v-flex xs12 md3 lg2 text-md-right>
                            <span class="caption grey--text">From Price (AUD)</span>
                          </v-flex>
                          <v-flex xs12 md2 lg2 text-md-right>
                            <span class="caption grey--text">Inclusions & Terms</span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                        <v-divider></v-divider>
                        <v-layout row wrap align-center my-3>
                          <v-flex xs12 md7 lg8>
                            <v-layout row align-center>
                              <v-flex shrink hidden-md-and-down>
                                <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                              </v-flex>
                              <v-flex>
                                <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                                <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                                <span class="d-block package-data">
                                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                                    <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Start location</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0" v-if="!deal.call">
                                    <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                    <v-tooltip top>
                                        <template v-slot:activator="{ on }">
                                          <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                        </template>
                                        <span>Copy Package Code</span>
                                    </v-tooltip>
                                  </v-chip>
                                  <v-chip label color="#f5f5f5" disabled class="mb-0" v-else>
                                    <b>Call ANZCRO to Book</b><v-icon class="mx-2" size="14" :color="kiwiGreen">phone</v-icon>
                                  </v-chip>
                                </span>
                              </v-flex>
                            </v-layout>
                          </v-flex>
                          <v-flex xs12 sm6 md3 lg2 text-md-right>
                            <span class="headline d-block font-weight-medium mb-2 kiwiGreen">${{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                            <span class="caption d-block grey--text">{{deal.complement}}</span>
                          </v-flex>
                          <v-flex xs12 sm6 md2 lg2 text-sm-right>
                            <v-btn large class="card-with-border elevation-0 mx-0 px-2" :color="lightGrey" :href="deal.itinerary" target="_blank"><v-icon color="grey" size="18" class="mr-1">launch</v-icon> View</v-btn>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
        </v-flex>
      </v-layout>
  
  
    <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
    </v-container>
  </template>
  <script>
  export default {
    data: () => ({
      copied: false,
      copiedText: '',
      sections : [
        {
          title : 'Deals',
          deals : [
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/10/4d-auckland-waiheke_thumbnail-136x136-1.jpg',
              name : '4 Day Auckland & Waiheke',
              price : '2629',
              complement : 'per person (twin share)',
              code : 'LUX234DAWI',
              location : 'Auckland',
              valid: '01 Nov 2023 - 30 Jun 2024',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/10/4-Day-Auckland-Waiheke-NZD-Flyer.pdf',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/10/5d-bay-of-islands-luxury-escape_thumbnail-136x136-1.jpg',
              name : '5 Day Bay of Islands Luxury Escape',
              price : '3429 ',
              complement : 'per person (twin share)',
              code : 'LUX234NBOILE',
              location : 'Auckland',
              valid: '01 Oct 2023 - 31 Mar 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/10/5-Day-Bay-of-Islands-Luxury-Escape-NZD-Flyer.pdf',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/10/5d-luxury-wine-wilderness_thumbnail-136x136-1.jpg',
              name : '5 Day Luxury Wine & Wilderness',
              price : '4149 ',
              complement : 'per person (twin share)',
              code : 'LUX234NLWW',
              location : 'Queenstown',
              valid: '01 Oct 2023 - 31 Mar 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/10/5-Day-Luxury-Wine-and-Wilderness-NZD-Flyer.pdf',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/10/6d-coastal-chic_thumbnail-136x136-1.jpg',
              name : '6 Day Coastal Chic',
              price : '4599',
              complement : 'per person (twin share)',
              code : 'LUX236DCC',
              location : 'Christchurch',
              valid: '14 Dec 2023 - 31 Mar 2024',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/10/6-Day-Coastal-Chic-NZD-Flyer.pdf',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/10/6d-northern-charms_thumbnail-136x136-1.jpg',
              name : '6 Day Northern Charms',
              price : '4669',
              complement : 'per person (twin share)',
              code : 'LUX236DNC',
              location : 'Auckland',
              valid: '01 Dec 2023 - 30 Sep 2024',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/10/6-Day-Northern-Charms-NZD-Flyer.pdf',
              call : false
            },
            {
              thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/10/7d-luxury-alpine-sea_thumbnail-136x136-1.jpg',
              name : '7 Day Luxury Alpine & Sea',
              price : '3619',
              complement : 'per person (twin share)',
              code : 'LUX237DLAS',
              location : 'Christchurch',
              valid: '01 Oct 2023 - 31 Mar 2025',
              itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/10/7-Day-Luxury-Alpine-and-Sea-NZD-Flyer.pdf',
              call : false
            },
          ]
        },
      ]
    }),
    mounted: function() {
      const el = document.scrollingElement || document.documentElement;
      el.scrollTop = 0;
    },
    methods: {
      top() {
        document.documentElement.scrollTop = 0;
      },
      copyCode(event) {
        if(event){
          let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
          window.getSelection().selectAllChildren(copyCode);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyCode.innerHTML);
          this.copiedText = 'Package code copied to clipboard';
          this.copied = true;
        }
      },
      copyLocation(event) {
        if(event){
          let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
          window.getSelection().selectAllChildren(copyLocation);
          document.execCommand("copy");
          window.getSelection().removeAllRanges();
          //alert(copyLocation.innerHTML);
          this.copiedText = 'Package start location copied to clipboard';
          this.copied = true;
        }
      }
    },
  };
  </script>
  
  <style>
  .gradient-bar{
    width: 100%;
    height: 9px;
    background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
    background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
  }
  
  .deals-content{
    margin-top: -60px;
  }
  
  .package-data .v-chip{
    color:#9e9e9e;
    margin-left: 0;
  }
  
  .package-data .v-chip .v-chip__content{
    padding-right: 6px;
  }
  
  .deal-thumbnail{
    width: 88px;
    height: 88px;
  }
  </style>
  