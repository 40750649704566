<template>
  <v-container pa-0 fluid>
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2022/06/hero-deals-south-australia-2-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.2) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <img src="https://cdn.anzcro.com.au/wp-content/uploads/2022/06/south-australia-deals-stacked.png" alt="Reward Your Wonder in South Australia" class="campaign-title stacked" width="480" height="300" />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Central Flinders - Photo: SATC</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="gradient-bar"></div>

    <v-layout row>
      <v-flex mb-5>
          <v-container grid-list-lg fill-height>
            <v-layout row wrap fill-height>
              <v-flex xs12 py-0 my-0>
                <v-card tile class="pa-0 elevation-0 deals-content">
                  <v-card-text class="pa-5">
                    <v-layout row wrap>
                      <v-flex xs12 md10 py-0 my-0>
                        <span
                          class="headline font-weight-medium kiwiGreen d-block mb-3"
                        >Let South Australia Reward Your Wonder!</span>
                        <p>Dramatic coastlines, world-class wineries and an abundance of wildlife, South Australia offers experiences that will reward wonder around every turn. From the boutique city of Adelaide, providing a gateway to South Australia and bursting with culture, entertainment and flavour, to over 200 cellar doors on Adelaide’s doorstep in regions like the Barossa, Adelaide Hills and McLaren Vale.</p>
                        <p>There’s a lot more to see and do, check out these fantastic holiday packages, and as always, all packages can be customised.</p>
                      </v-flex>
                      <v-flex xs12 md2 py-0 my-0 text-xs-left text-md-center>
                        <img src="https://cdn.anzcro.com.au/wp-content/uploads/2022/06/adelaide-logo-192.jpg" alt="New Zealand Coach Tour Earlybird Sale" class="campaign-sticker kirra-early-bird" width="96" height="99" />
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                <v-card tile dark class="pa-0 ma-0 elevation-0">
                  <v-card-text class="px-3 py-2">
                    <span
                      class="headline font-weight-medium d-block"
                    >{{section.title}}</span>
                  </v-card-text>
                </v-card>
                <v-card tile class="px-3 py-3 elevation-0">
                  <v-layout row wrap ma-0>
                    <v-flex xs12 pa-0 hidden-sm-and-down>
                      <v-layout row wrap my-0>
                        <v-flex xs12 md7 lg8>
                          <span class="caption grey--text">Package Deal</span>
                        </v-flex>
                        <v-flex xs12 md3 lg2 text-md-right>
                          <span class="caption grey--text">From Price (NZD)</span>
                        </v-flex>
                        <v-flex xs12 md2 lg2 text-md-right>
                          <span class="caption grey--text">Inclusions & Terms</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                      <v-divider></v-divider>
                      <v-layout row wrap align-center my-3>
                        <v-flex xs12 md7 lg8>
                          <v-layout row align-center>
                            <v-flex shrink hidden-md-and-down>
                              <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                            </v-flex>
                            <v-flex>
                              <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                              <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                              <span class="d-block package-data">
                                <v-chip label color="#f5f5f5" disabled class="mb-0">
                                  <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                  <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                      </template>
                                      <span>Copy Start location</span>
                                  </v-tooltip>
                                </v-chip>
                                <v-chip label color="#f5f5f5" disabled class="mb-0" v-if="!deal.call">
                                  <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                  <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                      </template>
                                      <span>Copy Package Code</span>
                                  </v-tooltip>
                                </v-chip>
                                <v-chip label color="#f5f5f5" disabled class="mb-0" v-else>
                                  <b>Call ANZCRO to Book</b><v-icon class="mx-2" size="14" :color="kiwiGreen">phone</v-icon>
                                </v-chip>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm6 md3 lg2 text-md-right>
                          <span class="headline d-block font-weight-medium mb-2 kiwiGreen">${{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                          <span class="caption d-block grey--text">{{deal.complement}}</span>
                        </v-flex>
                        <v-flex xs12 sm6 md2 lg2 text-sm-right>
                          <v-btn large class="card-with-border elevation-0 mx-0 px-2" :color="lightGrey" :href="deal.itinerary" target="_blank"><v-icon color="grey" size="18" class="mr-1">launch</v-icon> View</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
      </v-flex>
    </v-layout>


  <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    copied: false,
    copiedText: '',
    sections : [
      /*
      {
        title : 'Deals',
        deals : [
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/deal-sa-food.jpg',
            name : '4 Day Adelaide Food, Wine & Dine',
            price : '875',
            complement : 'per person (twin share)',
            code : 'ANZAU234DAFWD',
            location : 'Adelaide (ADL)',
            valid: '03 Jul 2023 - 29 Mar 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/2-Deal-Food-Wine-Delights-NZD.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/deal-pink-lake.jpg',
            name : '4 Nights Barossa & the Pink Lake',
            price : '1189',
            complement : 'per person (twin share)',
            code : 'ANZ234NPINK',
            location : 'Adelaide (ADL)',
            valid: '01 Jul 2023 - 29 Mar 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/Barossa-The-Pink-Lake-NZD-20230621.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/deal-sa-bike.jpg',
            name : '4 Day Bike About Barossa',
            price : '1299',
            complement : 'per person (twin share)',
            code : 'ANZAU234DBAB',
            location : 'Adelaide (ADL)',
            valid: '04 Jul 2023 - 26 Mar 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/2-Deal-Food-Wine-Delights-NZD.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/deal-sa-eyre.jpg',
            name : '6 Day Explore Eyre Peninsula',
            price : '1339',
            complement : 'per person (twin share)',
            code : 'ANZAU236DEEP',
            location : 'Adelaide (ADL)',
            valid: '03 Jul 2023 - 25 Mar 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/2-Deal-Eyre-Peninsular-GOR-NZD-1.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/deal-sa-traverse.jpg',
            name : '7 Day Melbourne to Adelaide Traverse',
            price : '2435',
            complement : 'per person (twin share)',
            code : 'ANZAU237DMAT',
            location : 'Melbourne (MEL)',
            valid: '03 Sep - 16 Dec 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/2-Deal-Eyre-Peninsular-GOR-NZD-1.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/deal-sa-lux.jpg',
            name : '6 Day Luxury Nature & Wildlife',
            price : '8399',
            complement : 'per person (twin share)',
            code : 'ANZAU236DLNWI',
            location : 'Adelaide (ADL)',
            valid: '08 Dec 2023 - 25 Mar 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/06/ANZAU236DLNWI-6-Day-Luxury-Nature-Wildlife-NZD.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-sa-02.jpg',
            name : '5 Day Boutique Adelaide',
            price : '425',
            complement : 'per person (twin share)',
            code : 'ANZSA225DBA',
            location : 'Adelaide (ADL)',
            valid: '01 Jul 2022 - 31 Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/5-Day-Adelaide-City-NZD.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-sa-06.jpg',
            name : '6 Day Barossa Food & Wine Safari',
            price : '1285',
            complement : 'per person (twin share)',
            code : 'ANZSA226DBFW',
            location : 'Adelaide (ADL)',
            valid: '04 Jul 2022 - 09 Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/6-Day-Wine-Safari-NZD.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-sa-01.jpg',
            name : '8 Day City, Wine and Outback',
            price : '2085',
            complement : 'per person (twin share)',
            code : 'ANZSA228DCWO',
            location : 'Adelaide (ADL)',
            valid: '07 Jul 2022 - 31 Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/8-Day-Rugged-Outback-Adventure-NZD.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-sa-04.jpg',
            name : '6 Day Adelaide Wellness Escape',
            price : '2499',
            complement : 'per person (twin share)',
            code : 'ANZSA226DAHWE',
            location : 'Adelaide (ADL)',
            valid: '10 Sep 2022 - 17 Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/08/6-Day-Adelaide-Wellnes-NZD.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-sa-05.jpg',
            name : '6 Day Kangaroo Island Explorer',
            price : '1619',
            complement : 'per person (twin share)',
            code : 'ANZSA226DKIE',
            location : 'Adelaide (ADL)',
            valid: '07 Jul 2022 - 31 Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/6-Day-Kangaroo-Island-Flyer-NZD.pdf',
            call : false
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/deal-sa-03.jpg',
            name : '5 Day Extraordinary South Australia',
            price : '2599',
            complement : 'per person (twin share)',
            code : 'ANZSA224NESA',
            location : 'Adelaide (ADL)',
            valid: '01 Aug 2022 - 31 Mar 2023',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2022/06/5-Day-New-Extraordinary-South-Australia-Flyer-NZD.pdf',
            call : false
          },        
        ]
      },
      */
    ]
  }),
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
    copyCode(event) {
      if(event){
        let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
        window.getSelection().selectAllChildren(copyCode);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyCode.innerHTML);
        this.copiedText = 'Package code copied to clipboard';
        this.copied = true;
      }
    },
    copyLocation(event) {
      if(event){
        let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
        window.getSelection().selectAllChildren(copyLocation);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyLocation.innerHTML);
        this.copiedText = 'Package start location copied to clipboard';
        this.copied = true;
      }
    }
  },
};
</script>

<style>
.gradient-bar{
  width: 100%;
  height: 9px;
  background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
  background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
}

.deals-content{
  margin-top: -60px;
}

.package-data .v-chip{
  color:#9e9e9e;
  margin-left: 0;
}

.package-data .v-chip .v-chip__content{
  padding-right: 6px;
}

.deal-thumbnail{
  width: 88px;
  height: 88px;
}
</style>
