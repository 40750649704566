<template>
  <v-container pa-0 fluid>
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2024/01/hero-winter-holiday-collection-2024-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.25) 15%, rgba(22,22,22,0.15) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <img src="https://cdn.anzcro.com.au/wp-content/uploads/2024/01/new-zealand-winter-holiday-collection-stacked.png" alt="New Zealand Winter Holiday Collection" class="campaign-title stacked" width="480" height="300" />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Harris Mountain Heli-Skiing</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="gradient-bar"></div>

    <v-layout row>
      <v-flex mb-5>
          <v-container grid-list-lg fill-height>
            <v-layout row wrap fill-height>
              <v-flex xs12 py-0 my-0>
                <v-card tile class="pa-0 elevation-0 deals-content">
                  <v-card-text class="pa-5">
                    <v-layout row wrap>
                      <v-flex xs12 md10 py-0 my-0>
                        <p class="subheading font-weight-medium kiwiGreen mb-2 mt-2">Introducing Winter Holiday Collection 2024</p>
                        <p>Discover a selection of New Zealand holiday packages, early bird lift passes and all things ski with our brand new 20 page <a target="_blank" href="https://issuu.com/holidayexperts/docs/whc_nzd_issuu_2024?fr=xKAE9_zU1NQ">eBrochure</a>. This carefully-curated collection will showcase five spectacular South Island ski fields, handy maps, trending restaurants and will serve as a Travel Agent’s best-friend during this years busy snow season. This winter collection features 12 customisable holiday packages including two unique self-drive itineraries that allow guests to embark on a world-famous rail journey, explore prized-ski fields, hike giant glaciers, soak in thermal hot springs and everything in between.</p>
                        <!--<span class="subheading font-weight-medium kiwiGreen d-block mb-2">2023 is shaping up to be the most in-demand ski season of the past 10 years.</span>
                        <p>Accommodation in Queenstown, Wanaka & Methven can be limited during winter, so consider booking early to secure your preferred holiday. Early Bird specials are now available, which makes these snow holidays the best value you will find!</p>-->
                      </v-flex>
                      <v-flex xs12 md2 py-0 my-0 text-xs-center>
                        <a target="_blank" href="https://issuu.com/holidayexperts/docs/whc_nzd_issuu_2024?fr=xKAE9_zU1NQ"><img src="https://cdn.anzcro.com.au/wp-content/uploads/2023/01/brochure-cover-winter-holidays-2024.jpg" alt="New Zealand Winter Holiday Collection eBrochure Cover" class="campaign-sticker kirra-early-bird" width="124" height="175" /></a>
                        <small class="kiwiGreen d-block mt-2" style="line-height:1.2;"><a target="_blank" href="https://issuu.com/holidayexperts/docs/whc_nzd_issuu_2024?fr=xKAE9_zU1NQ"><b>View eBrochure</b></a></small>
                      </v-flex>
                    </v-layout>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                <v-card tile dark class="pa-0 ma-0 elevation-0">
                  <v-card-text class="px-3 py-2">
                    <span
                      class="headline font-weight-medium d-block"
                    >{{section.title}}</span>
                  </v-card-text>
                </v-card>
                <v-card tile class="px-3 py-3 elevation-0">
                  <v-layout row wrap ma-0>
                    <v-flex xs12 pa-0 hidden-sm-and-down>
                      <v-layout row wrap my-0>
                        <v-flex xs12 md7 lg8>
                          <span class="caption grey--text">Package Deal</span>
                        </v-flex>
                        <v-flex xs12 md3 lg2 text-md-right>
                          <span class="caption grey--text">From Price (NZD)</span>
                        </v-flex>
                        <v-flex xs12 md2 lg2 text-md-center>
                          <span class="caption grey--text">Agent Resources</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                      <v-divider></v-divider>
                      <v-layout row wrap align-center my-3>
                        <v-flex xs12 md7 lg8>
                          <v-layout row align-center>
                            <v-flex shrink hidden-md-and-down>
                              <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                            </v-flex>
                            <v-flex>
                              <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                              <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                              <span class="d-block package-data">
                                <v-chip label color="#f5f5f5" disabled class="mb-0">
                                  <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                  <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                      </template>
                                      <span>Copy Start location</span>
                                  </v-tooltip>
                                </v-chip>
                                <v-chip label color="#f5f5f5" disabled class="mb-0">
                                  <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                  <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                      </template>
                                      <span>Copy Package Code</span>
                                  </v-tooltip>
                                </v-chip>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm6 md3 lg2 text-md-right>
                          <span class="headline d-block font-weight-medium mb-2 kiwiGreen">${{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                          <span class="caption d-block grey--text">{{deal.complement}}</span>
                        </v-flex>
                        <v-flex xs12 sm6 md2 lg2 text-sm-right text-md-center>
                          <v-tooltip top v-if="deal.sm_tile">
                            <template v-slot:activator="{ on }">
                              <v-btn large v-on="on" icon class="card-with-border elevation-0 mr-1 px-0" color="white" :href="deal.sm_tile" target="_blank"><v-icon :color="kiwiGreen" size="22" class="">image</v-icon></v-btn>
                            </template>
                            <span>View Social Media Tile</span>
                          </v-tooltip>
                          <v-tooltip top v-if="deal.itinerary">
                            <template v-slot:activator="{ on }">
                              <v-btn large v-on="on" icon class="card-with-border elevation-0 mx-0 px-0" :color="kiwiGreen" :href="deal.itinerary" target="_blank"><v-icon color="white" size="22" class="">description</v-icon></v-btn>
                            </template>
                            <span>View Flyer</span>
                          </v-tooltip>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
      </v-flex>
    </v-layout>


  <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    copied: false,
    copiedText: '',
    sections : [
      {
        title : 'Queenstown Deals - Twin Share',
        deals : [
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/01/deals-ski-qs.jpg',
            name : '7 Nights Ski Queenstown',
            price : '959',
            complement : 'per person (twin share)',
            code : 'SB247NSKI',
            location : 'Queenstown (ZQN)',
            valid: '01 Jul - 30 Sep 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/7-Nights-Ski-Queenstown-NZD-Flyer.pdf',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/7-Nights-Ski-Queenstown-NZD.png',
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/01/deals-ski-qsoak.jpg',
            name : '7 Nights Ski & Soak Queenstown',
            price : '1259',
            complement : 'per person (twin share)',
            code : 'SB247NSOAK',
            location : 'Queenstown (ZQN)',
            valid: '01 Jul - 30 Sep 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/7-Nights-Ski-Soak-Queenstown-NZD-Flyer.pdf',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/7-Nights-Ski-Soak-Queenstown-NZD.png',
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/01/deals-ski-qss.jpg',
            name : '7 Nights Southern Ski & Sounds',
            price : '2189',
            complement : 'per person (twin share)',
            code : 'SB247NSSS',
            location : 'Queenstown (ZQN)',
            valid: '01 Jul - 30 Sep 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/7-Nights-Southern-Ski-Sounds-NZD-Flyer.pdf',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/7-Nights-Southern-Ski-Sounds-NZD.png',
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/01/deals-ski-qwd.jpg',
            name : '7 Nights Ski, Wine & Dine',
            price : '2429',
            complement : 'per person (twin share)',
            code : 'SB247NSWD',
            location : 'Queenstown (ZQN)',
            valid: '01 Jul - 30 Sep 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/7-Nights-Ski-Wine-Dine-NZD-Flyer.pdf',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/7-Nights-Ski-Wine-Dine-NZD.png',
          },
        ]
      },
      {
        title : 'Queenstown Family Deals',
        deals : [
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/01/deals-ski-qfh.jpg',
            name : '6 Nights Family Winter Fun',
            price : '3659',
            complement : 'per family (2Ad+2Ch)',
            code : 'SB246NQFWF',
            location : 'Queenstown (ZQN)',
            valid: '01 Jul - 30 Sep 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/6-Nights-Family-Winter-Fun-NZD-Flyer.pdf',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/6-Nights-Family-Winter-Fun-NZD.png',
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/01/deals-ski-qfa.jpg',
            name : '6 Nights Queenstown Family Ski',
            price : '4549',
            complement : 'per family (2Ad+2Ch)',
            code : 'SB246NQFS',
            location : 'Queenstown (ZQN)',
            valid: '01 Jul - 30 Sep 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/6-Nights-Queenstown-Family-Ski-NZD-Flyer.pdf',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/6-Nights-Queenstown-Family-Ski-NZD.png',
          },
        ]
      },
      {
        title : 'Wanaka Deals',
        deals : [
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/01/deals-ski-wan.jpg',
            name : '7 Nights Ski Wanaka',
            price : '929',
            complement : 'per person (twin share)',
            code : 'SB247NSSW',
            location : 'Wanaka (WKA)',
            valid: '22 Jul - 30 Sep 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/7-Nights-Ski-Wanaka-NZD-Flyer.pdf',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/7-Nights-Ski-Wanaka-NZD.png',
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/01/deals-ski-waf.jpg',
            name : '7 Nights Ski Wanaka Family',
            price : '5539',
            complement : 'per family (2Ad+2Ch)',
            code : 'SB247NWFSH',
            location : 'Wanaka (WKA)',
            valid: '22 Jul - 30 Sep 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/7-Nights-Ski-Wanaka-Family-Snow-Holiday-NZD-Flyer.pdf',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/7-Nights-Ski-Wanaka-Family-Snow-Holiday-NZD.png',
          },
        ]
      },
      {
        title : 'Methven/Mt Hutt Deals',
        deals : [
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/01/deals-ski-mh.jpg',
            name : '6 Nights Ski Mt Hutt',
            price : '939',
            complement : 'per person (twin share)',
            code : 'SB246NSMH',
            location : 'Methven (MET)',
            valid: '01 Jul - 30 Sep 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/6-Nights-Ski-Mt-Hutt-NZD-Flyer.pdf',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/6-Nights-Ski-Mt-Hutt-NZD.png',
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/01/deals-ski-mhf.jpg',
            name : '6 Nights Mt Hutt Family Ski Holiday',
            price : '2859',
            complement : 'per family (2Ad+2Ch)',
            code : 'SB246NMHFSH',
            location : 'Methven (MET)',
            valid: '01 Jul - 30 Sep 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/6-Nights-Mt-Hutt-Family-Ski-Holiday-NZD-Flyer.pdf',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/6-Nights-Mt-Hutt-Family-Ski-Holiday-NZD.png',
          },
        ]
      },
      {
        title : 'South Island Self-Drive Deals',
        deals : [
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/01/deals-ski-sh.jpg',
            name : '8 Nights The Snow Highway',
            price : '2099',
            complement : 'per person (twin share)',
            code : 'SB248NTSH',
            location : 'Christchurch (CHC)',
            valid: '01 Jul - 30 Sep 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/8-Nights-The-Snow-Highway-NZD-Flyer.pdf',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/8-Nights-The-Snow-Highway-NZD.png',
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2023/01/deals-ski-sre.jpg',
            name : '11 Nights Snow & Rail Explorer',
            price : '2729',
            complement : 'per person (twin share)',
            code : 'SB2411NSRE',
            location : 'Christchurch (CHC)',
            valid: '01 Jul - 30 Sep 2024',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/03/11-Nights-Snow-Rail-Explorer-NZD-Flyer.pdf',
            sm_tile : 'https://cdn.anzcro.com.au/wp-content/uploads/2024/02/11-Nights-Snow-Rail-Explorer-NZD.png',
          },
        ]
      },
    ]
  }),
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
    copyCode(event) {
      if(event){
        let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
        window.getSelection().selectAllChildren(copyCode);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyCode.innerHTML);
        this.copiedText = 'Package code copied to clipboard';
        this.copied = true;
      }
    },
    copyLocation(event) {
      if(event){
        let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
        window.getSelection().selectAllChildren(copyLocation);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyLocation.innerHTML);
        this.copiedText = 'Package start location copied to clipboard';
        this.copied = true;
      }
    }
  },
};
</script>

<style>
.gradient-bar{
  width: 100%;
  height: 9px;
  background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
  background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
}

.deals-content{
  margin-top: -60px;
}

.package-data .v-chip{
  color:#9e9e9e;
  margin-left: 0;
}

.package-data .v-chip .v-chip__content{
  padding-right: 6px;
}

.deal-thumbnail{
  width: 88px;
  height: 88px;
}
</style>
