<template>
  <v-container pa-0 fluid>
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2021/11/hero-ocean-alpine-sea-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.35) 15%, rgba(22,22,22,0.1) 65%"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <!--<h1 class="white--text display-1 font-weight-bold mb-3">
                            Hot Offers on<br/>Holidays at Home
                          </h1>-->
                          <img src="https://cdn.anzcro.com.au/wp-content/uploads/2021/11/ocean-alpine-sea-nzd-deals-stacked.png" alt="Discover the Ocean, Alpine and Sea Touring Route" class="campaign-title stacked" width="480" height="300" />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Kaikoura - Photo: Dan Kerrins</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="gradient-bar"></div>

    <v-layout row>
      <v-flex mb-5>
          <v-container grid-list-lg fill-height>
            <v-layout row wrap fill-height>
              <v-flex xs12 py-0 my-0>
                <v-card tile class="pa-0 elevation-0 deals-content">
                  <v-card-text class="pa-5">
                    <span
                      class="headline font-weight-medium kiwiGreen d-block mb-3"
                    >Catch your breath on the road less travelled, featuring Kaikoura, Hurunui and West Coast.</span>
                    <p>The road less explored is the one that leads to discoveries and realisations. It is the road used by wanderers, explorers and the ones seeking deeper and meaningful connections. The road less travelled isn’t a marked path that to be followed, written on a map or found somewhere on Instagram – it’s the road you make, and reinvent every day. The road less travelled is many things to many people, but to all it’s the road that leaves you changed.</p>
                    <p><b>Watch the recorded webinar:</b> <a  target="_blank" href="https://vimeo.com/649745410">Webinar - Ocean, Alpine & Sea Touring Route</a><br/>
                    <b>Read the blog:</b> <a href="/the-insider/ocean-alpine-sea-touring-route/">Discover the Ocean, Alpine and Sea Touring Route</a><br/></p>
                  </v-card-text>
                </v-card>
              </v-flex>
              <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
                <v-card tile dark class="pa-0 ma-0 elevation-0">
                  <v-card-text class="px-3 py-2">
                    <span
                      class="headline font-weight-medium d-block"
                    >{{section.title}}</span>
                  </v-card-text>
                </v-card>
                <v-card tile class="px-3 py-3 elevation-0">
                  <v-layout row wrap ma-0>
                    <v-flex xs12 pa-0 hidden-sm-and-down>
                      <v-layout row wrap my-0>
                        <v-flex xs12 md7 lg8>
                          <span class="caption grey--text">Package Deal</span>
                        </v-flex>
                        <v-flex xs12 md3 lg2 text-md-right>
                          <span class="caption grey--text">From Price (NZD)</span>
                        </v-flex>
                        <v-flex xs12 md2 lg2 text-md-right>
                          <span class="caption grey--text">Inclusions & Terms</span>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                    <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                      <v-divider></v-divider>
                      <v-layout row wrap align-center my-3>
                        <v-flex xs12 md7 lg8>
                          <v-layout row align-center>
                            <v-flex shrink hidden-md-and-down>
                              <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                            </v-flex>
                            <v-flex>
                              <span class="title d-block font-weight-medium mb-2">{{deal.name}}</span>
                              <span class="caption d-block mb-1 grey--text"><b>Dates:</b> {{deal.valid}}</span>
                              <span class="d-block package-data">
                                <v-chip label color="#f5f5f5" disabled class="mb-0">
                                  <b>Start:</b>&nbsp;<span class="package-start-location">{{deal.location}}</span>
                                  <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                      </template>
                                      <span>Copy Start location</span>
                                  </v-tooltip>
                                </v-chip>
                                <v-chip label color="#f5f5f5" disabled class="mb-0">
                                  <b>Code:</b>&nbsp;<span class="package-code">{{deal.code}}</span>
                                  <v-tooltip top>
                                      <template v-slot:activator="{ on }">
                                        <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn>
                                      </template>
                                      <span>Copy Package Code</span>
                                  </v-tooltip>
                                </v-chip>
                              </span>
                            </v-flex>
                          </v-layout>
                        </v-flex>
                        <v-flex xs12 sm6 md3 lg2 text-md-right>
                          <span class="headline d-block font-weight-medium mb-2 kiwiGreen">${{parseInt(deal.price).toLocaleString()}}<sup>*</sup></span>
                          <span class="caption d-block grey--text">{{deal.complement}}</span>
                        </v-flex>
                        <v-flex xs12 sm6 md2 lg2 text-sm-right>
                          <v-btn large class="card-with-border elevation-0 mx-0 px-2" :color="lightGrey" :href="deal.itinerary" target="_blank"><v-icon color="grey" size="18" class="mr-1">launch</v-icon> View</v-btn>
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
      </v-flex>
    </v-layout>


  <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    copied: false,
    copiedText: '',
    sections : [
      {
        title : 'Deals',
        deals : [
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/11/deal-thumbnail-ocean-alpine-sea-nzd-01.jpg',
            name : '3 Nights Alpine Pacific Triangle',
            price : '625',
            complement : 'per person (twin share)',
            code : 'ANZ223NAPTV2',
            location : 'Hanmer Springs (HAN)',
            valid: '05 Jan 2022 - 31 Mar 2022',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/11/Ocean-Alpine-Sea-Flyer-NZD.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/11/deal-thumbnail-ocean-alpine-sea-nzd-02.jpg',
            name : '3 Nights Coastal Alpine Adventure',
            price : '699',
            complement : 'per person (twin share)',
            code : 'ANZ223NCAAV2',
            location : 'Kaikoura (KAI)',
            valid: '05 Jan 2022 - 14 Apr 2022',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/11/Ocean-Alpine-Sea-Flyer-NZD.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/11/hero-ocean-alpine-sea-150x150.jpg',
            name : '4 Nights Coast to Coast',
            price : '1105',
            complement : 'per person (twin share)',
            code : 'ANZ224NCCRT',
            location : 'Punakaiki (PUN)',
            valid: '05 Jan 2022 - 14 Apr 2022',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/11/Coast-to-Coast-Flyer-NZD.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/11/deal-thumbnail-ocean-alpine-sea-nzd-03.jpg',
            name : '3 Nights Hot Pools & Heritage Parks Family',
            price : '1519',
            complement : 'per family (2Ad+2Ch)',
            code : 'ANZ223NHPHPF',
            location : 'Hanmer Springs (HAN)',
            valid: '05 Jan 2022 - 30 Apr 2022',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/11/Ocean-Alpine-Sea-Flyer-NZD.pdf'
          },
          {
            thumbnail : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/11/deal-thumbnail-ocean-alpine-sea-nzd-04.jpg',
            name : '5 Nights Whales, Pools & Gold Family',
            price : '1999',
            complement : 'per family (2Ad+2Ch)',
            code : 'ANZ225NWPG',
            location : 'Kaikoura (KAI)',
            valid: '05 Jan 2022 - 30 Apr 2022',
            itinerary : 'https://cdn.anzcro.com.au/wp-content/uploads/2021/11/Ocean-Alpine-Sea-Flyer-NZD.pdf'
          },
        ]
      },
    ]
  }),
  mounted: function() {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
    copyCode(event) {
      if(event){
        let copyCode = event.target.closest('.v-chip__content').querySelector('.package-code');
        window.getSelection().selectAllChildren(copyCode);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyCode.innerHTML);
        this.copiedText = 'Package code copied to clipboard';
        this.copied = true;
      }
    },
    copyLocation(event) {
      if(event){
        let copyLocation = event.target.closest('.v-chip__content').querySelector('.package-start-location');
        window.getSelection().selectAllChildren(copyLocation);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyLocation.innerHTML);
        this.copiedText = 'Package start location copied to clipboard';
        this.copied = true;
      }
    }
  },
};
</script>

<style>
.gradient-bar{
  width: 100%;
  height: 9px;
  background: -webkit-gradient(linear,left top,right top,from(#65b32e),color-stop(#d2d2d2),to(#30302f));
  background: linear-gradient(90deg,#65b32e,#d2d2d2,#30302f);
}

.deals-content{
  margin-top: -60px;
}

.package-data .v-chip{
  color:#9e9e9e;
  margin-left: 0;
}

.package-data .v-chip .v-chip__content{
  padding-right: 6px;
}

.deal-thumbnail{
  width: 88px;
  height: 88px;
}
</style>
