<template>
  <v-container pa-0 fluid>
    <v-layout row>
      <v-flex>
        <v-card flat tile dark class="hero-home-slider">
          <v-carousel height="auto" class="elevation-0 static-hero" hide-controls>
            <v-carousel-item>
              <v-img
                src="https://cdn.anzcro.com.au/wp-content/uploads/2021/06/home-hero-apc-deals-nz-scaled.jpg"
                gradient="to bottom, rgba(22,22,22,1), rgba(22,22,22,.45) 15%, rgba(22,22,22,0.45) 65%, rgba(22,22,22,0.45)"
                class="home-hero"
              >
                <v-container fill-height>
                  <v-layout dark column fill-height justify-space-between pt-5>
                    <v-flex text-xs-center class="home-hero-cta">
                      <v-layout row align-center fill-height class="fill-height-fix-ios">
                        <v-flex>
                          <!--<h1 class="white--text display-1 font-weight-bold mb-3">
                            Hot Offers on<br/>Holidays at Home
                          </h1>-->
                          <img src="https://cdn.anzcro.com.au/wp-content/uploads/2021/06/apc-deals-stacked-2.png" alt="Ausytralia Package Collection" class="campaign-title stacked" width="480" height="300" />
                        </v-flex>
                      </v-layout>
                    </v-flex>
                  </v-layout>
                </v-container>
                <div class="hero-caption hidden-xs-only">
                  <small>Jacarandas, Sydney Harbour - Photo: Destination NSW</small>
                </div>
              </v-img>
            </v-carousel-item>
          </v-carousel>
        </v-card>
      </v-flex>
    </v-layout>
    <div class="gradient-bar"></div>

    <v-layout row>
      <v-flex mb-5>
        <v-container grid-list-lg fill-height>
          <v-layout row wrap fill-height>
            <v-flex xs12 py-0 my-0>
              <v-card tile class="pa-0 elevation-0 deals-content">
                <v-card-text class="pa-5">
                  <span class="headline font-weight-medium kiwiGreen d-block mb-3">The perfect place to start your Aussie holiday travel planning.</span>
                  <p>Discover a curated showcase of holidays across every state and territory in Australia.</p>
                </v-card-text>
              </v-card>
            </v-flex>
            <v-flex xs12 py-0 my-0 v-for="(section, index) in sections" :key="index">
              <v-card tile dark class="pa-0 ma-0 elevation-0">
                <v-card-text class="pa-32">
                  <span class="title d-block">{{ section.title }}</span>
                </v-card-text>
              </v-card>
              <v-card tile class="px-3 py-3 elevation-0">
                <v-layout row wrap ma-0>
                  <v-flex xs12 pa-0 hidden-sm-and-down>
                    <v-layout row wrap my-0>
                      <v-flex xs12 md7 lg8>
                        <span class="caption grey--text">Package Deal</span>
                      </v-flex>
                      <v-flex xs12 md3 lg2 text-md-right>
                        <span class="caption grey--text">From Price (NZD)</span>
                      </v-flex>
                      <v-flex xs12 md2 lg2 text-md-right>
                        <span class="caption grey--text">Inclusions & More</span>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                  <v-flex xs12 pa-0 v-for="(deal, dindex) in section.deals" :key="dindex">
                    <v-divider></v-divider>
                    <v-layout row wrap align-center my-3>
                      <v-flex xs12 md7 lg8>
                        <v-layout row align-center>
                          <v-flex shrink hidden-md-and-down>
                            <v-img :src="deal.thumbnail" class="deal-thumbnail"></v-img>
                          </v-flex>
                          <v-flex>
                            <span class="title d-block font-weight-medium mb-2">{{
                              deal.name
                            }}</span>
                            <span class="caption d-block mb-1 grey--text"
                              ><b>Travel Dates:</b> {{ deal.valid }}</span
                            >
                            <span class="d-block package-data">
                              <v-chip label color="#f5f5f5" disabled class="mb-0">
                                <b>Start:</b>&nbsp;<span class="package-start-location">{{
                                  deal.location
                                }}</span>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-btn
                                      small
                                      icon
                                      v-on="on"
                                      class="mr-0"
                                      v-on:click="copyLocation"
                                      ><v-icon size="14" :color="kiwiGreen"
                                        >content_copy</v-icon
                                      ></v-btn
                                    >
                                  </template>
                                  <span>Copy Start location</span>
                                </v-tooltip>
                              </v-chip>
                              <v-chip label color="#f5f5f5" disabled class="mb-0">
                                <b>Code:</b>&nbsp;<span class="package-code">{{ deal.code }}</span>
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"
                                      ><v-icon size="14" :color="kiwiGreen"
                                        >content_copy</v-icon
                                      ></v-btn
                                    >
                                  </template>
                                  <span>Copy Package Code</span>
                                </v-tooltip>
                              </v-chip>
                            </span>
                          </v-flex>
                        </v-layout>
                      </v-flex>
                      <v-flex xs12 sm6 md3 lg2 text-md-right>
                        <span class="headline d-block font-weight-medium mb-2 kiwiGreen"
                          >${{ parseInt(deal.price).toLocaleString() }}<sup>*</sup></span
                        >
                        <span class="caption d-block grey--text">{{ deal.complement }}</span>
                      </v-flex>
                      <v-flex xs12 sm6 md2 lg2 text-sm-right>
                        <v-btn
                          v-if="deal.type == 'tile'"
                          large
                          class="card-with-border elevation-0 mx-0 px-3"
                          :color="lightGrey"
                          @click.stop="(details = true), selectDeal(index, dindex)"
                          ><v-icon color="grey" size="18" class="mr-1">launch</v-icon> View<span
                            class="hidden-md-and-up"
                            >&nbsp;Inclusions</span
                          ></v-btn
                        >
                        <v-btn
                          v-else
                          large
                          class="card-with-border elevation-0 mx-0 px-3"
                          :color="lightGrey"
                          :href="deal.link" target="_blank"
                          ><v-icon color="grey" size="18" class="mr-1">launch</v-icon> View<span
                            class="hidden-md-and-up"
                            >&nbsp;Inclusions</span
                          ></v-btn
                        >
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>

    <v-dialog v-model="details" width="1200">
      <v-card>
        <v-card-title class="headline grey lighten-5 px-4 font-weight-medium">
          <v-layout row align-center>
            <v-flex>
              <span
                class="d-block headline font-weight-bold"
              >{{sections[selectedSection].deals[selectedDeal].name}}</span>
            </v-flex>
            <v-flex shrink>
              <v-btn
                small
                :color="lightGrey"
                fab
                class="elevation-0 ma-0 card-with-border"
                @click="details=false;"
              >
                <v-icon size="24">close</v-icon>
              </v-btn>
            </v-flex>
          </v-layout>
        </v-card-title>
        <v-card-text class="pa-0">
          <v-layout row wrap>
            <v-flex xs12 lg8>
              <v-img :src="sections[selectedSection].deals[selectedDeal].details"></v-img>
            </v-flex>
            <v-flex xs12 lg4 pa-4>
              <span class="body-2 d-block mb-1">Terms & Conditions</span>
              <span class="caption d-block">{{ sections[selectedSection].deals[selectedDeal].terms }}</span>
              <v-btn large class="card-with-border elevation-0 mx-0 px-3 mt-5" :color="lightGrey" :href="sections[selectedSection].deals[selectedDeal].details" target="_blank"><v-icon color="grey" size="18" class="mr-1">download</v-icon> Save Image</v-btn>
              <div class="hidden-md-and-down">
                <span class="body-2 d-block mt-5 mb-2">Search Package in ANZCRO Connect</span>
                <span class="caption d-block mb-2"
                  >Travel Dates: {{ sections[selectedSection].deals[selectedDeal].valid }}</span
                >
                <span class="d-block package-data">
                  <v-chip label color="#f5f5f5" disabled class="mb-2">
                    <b>Start:</b>&nbsp;<span class="package-start-location">{{
                      sections[selectedSection].deals[selectedDeal].location
                    }}</span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn small icon v-on="on" class="mr-0" v-on:click="copyLocation"
                          ><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn
                        >
                      </template>
                      <span>Copy Start location</span>
                    </v-tooltip>
                  </v-chip><br/>
                  <v-chip label color="#f5f5f5" disabled class="mb-0">
                    <b>Code:</b>&nbsp;<span class="package-code">{{ sections[selectedSection].deals[selectedDeal].code }}</span>
                    <v-tooltip top>
                      <template v-slot:activator="{ on }">
                        <v-btn small icon v-on="on" class="mr-0" v-on:click="copyCode"
                          ><v-icon size="14" :color="kiwiGreen">content_copy</v-icon></v-btn
                        >
                      </template>
                      <span>Copy Package Code</span>
                    </v-tooltip>
                  </v-chip>
                </span>
              </div>
            </v-flex>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar :color="kiwiGreen" v-model="copied" bottom class="mb-4" timeout="2500">{{copiedText}} <v-btn flat @click="copied = false">Close</v-btn></v-snackbar>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    details: false,
    copied: false,
    copiedText: '',
    sections: [
      {
        title: "New South Wales",
        deals: [
          {
            type: 'tile',
            link: null,
            thumbnail:
              "https://cdn.anzcro.com.au/wp-content/uploads/2021/07/Australia-Packages_0622_Social-Media-Tiles1-150x150.jpg",
            name: "3 Day Sydney Daze",
            price: "399",
            complement: "per person (twin share)",
            code: "ANZAUPB21223DSD",
            location: "Sydney (SYD)",
            valid: "01 Sep 2021 - 31 Mar 2022",
            details: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/07/Australia-Packages_0622_Social-Media-Tiles1.jpg',
            terms: 'Package price is based on 2 people and priced in New Zealand Dollars. Pricing is correct at the time of submission but is subject to change without notice. Valid for travel from the 01 September 2021 to 31 March 2022. Blackout dates: 02-03 Oct 2021, 30 Dec 2021 – 10 Jan 2022 . Other Blackout/Surcharges may apply over special event periods and over school holidays. Inclusions are subject to change based on supplier operational capabilities. Transfers not included. Dinner Cruise switches to a 2 hour cruise from October and Operates Wednesday – Sunday. Quote package code: ANZAUPB21223DSD.',
          },
          {
            type: 'tile',
            link: null,
            thumbnail:
              "https://cdn.anzcro.com.au/wp-content/uploads/2021/07/Australia-Packages_0622_Social-Media-Tiles2-150x150.jpg",
            name: "4 Day Nature, Wine & Wildlife",
            price: "519",
            complement: "per person (twin share)",
            code: "ANZAUPB21224NWW",
            location: "Sydney (SYD)",
            valid: "01 Sep 2021 - 31 Mar 2022",
            details: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/07/Australia-Packages_0622_Social-Media-Tiles2.jpg',
            terms: 'Package price is based on 2 people and priced in New Zealand Dollars. Pricing is correct at the time of submission but is subject to change without notice. Valid for travel from the 01 September 2021 to 31 March 2022. Blackout dates: 02 – 10 Oct 2021 & 24 Dec 2021 – 03 Jan 2022. Blackout/Surcharges may apply over special event periods and over school holidays. Inclusions are subject to change based on supplier operational capabilities. Car hire is with Hertz Car Rental and subject to an excess conditions and based on return Sydney Airport. Price varies for stays Sun-Thurs & Fri- Sat nights at Blue Mountains property. Quote package code: ANZAUPB21224NWW.',
          },
          {
            type: 'tile',
            link: null,
            thumbnail:
              "https://cdn.anzcro.com.au/wp-content/uploads/2021/06/deal-thumbnail-apc-7.jpg",
            name: "5 Day Norfolk Island Vibes",
            price: "729",
            complement: "per person (twin share)",
            code: "ANZAUPB21225DNIV",
            location: "Norfolk Island (NSW) (NLK)",
            valid: "01 Apr 2021 - 31 Mar 2022",
            details: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/06/Norfolk-Island-Vibes-NZD-SM-TIle-updated.png',
            terms: 'Pricing based on 2 adults twin share. Travel from 01 Jun 2021 to 31 Mar 2022. Featured rate based on travel in Jan 2022 Blockout dates and seasonable surcharges may apply. Touring featured operates on selected days Contact ANZCRO for details.',
          },
          {
            type: 'tile',
            link: null,
            thumbnail:
              "https://cdn.anzcro.com.au/wp-content/uploads/2021/07/Australia-Packages_0622_Social-Media-Tiles5-150x150.jpg",
            name: "7 Day Pacific Coast Discovery",
            price: "1119",
            complement: "per person (twin share)",
            code: "ANZAUPB21227DPCD",
            location: "Sydney (SYD)",
            valid: "01 Sep 2021 - 31 Mar 2022",
            details: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/07/Australia-Packages_0622_Social-Media-Tiles5.jpg',
            terms: 'Package price is based on 2 people and priced in New Zealand Dollars. Pricing is correct at the time of submission but is subject to change without notice. Valid for travel from the 01 September 2021 to 31 March 2022. Blackout dates: 17 Sept - 4 Oct 21, 16 Dec 21 – 26 Jan 22& 1-6 Mar 22. Other blackout/Surcharges may apply over special event periods and over school holidays. Pricing at the Byron Bay property is based on a dynamic rate and can change dependent on inventory at the time of quotation. Inclusions are subject to change based on supplier operational capabilities. Car hire is with Hertz Car Rental and subject to an excess conditions and based on return Sydney Airport. Quote package code: ANZAUPB21227DPCD.',
          },
          {
            type: 'tile',
            link: null,
            thumbnail:
              "https://cdn.anzcro.com.au/wp-content/uploads/2021/07/Australia-Packages_0622_Social-Media-Tiles4-150x150.jpg",
            name: "6 Day Southern Delights",
            price: "1429",
            complement: "per person (twin share)",
            code: "ANZAUPB21226DSD",
            location: "Sydney (SYD)",
            valid: "01 Sep 2021 - 31 Mar 2022",
            details: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/07/Australia-Packages_0622_Social-Media-Tiles4.jpg',
            terms: 'Package price is based on 2 people and priced in New Zealand Dollars. Pricing is correct at the time of submission but is subject to change without notice. Valid for travel from the 01 September 2021 to 31 March 2022. Blackout dates: 1-3 Oct, 20 Nov – 28 Nov & 17 Dec 21 – 10 Jan 22. Other blackout/Surcharges may apply over special event periods and over school holidays. Inclusions are subject to change based on supplier operational capabilities. A non-refundable deposit is required to secure his package. Car hire is with Hertz Car Rental and subject to an excess conditions and based on return Sydney Airport. Price varies for stays Sun-Thurs & Fri- Sat nights at both Cape Jervis & Canberra Properties. Quote package code: ANZAUPB21226DSD.',
          },
          {
            type: 'tile',
            link: null,
            thumbnail:
              "https://cdn.anzcro.com.au/wp-content/uploads/2021/07/Australia-Packages_0622_Social-Media-Tiles3-150x150.jpg",
            name: "4 Day Private Gourmet Country Escape",
            price: "2059",
            complement: "per person (quad share)",
            code: "ANZAUPB21224DPGCE",
            location: "Sydney (SYD)",
            valid: "01 Sep 2021 - 20 Dec 2021",
            details: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/07/Australia-Packages_0622_Social-Media-Tiles3.jpg',
            terms: 'Package price is based on 4 people and priced in New Zealand Dollars. Pricing is correct at the time of submission but is subject to change without notice. Valid for travel from the 01 September 2021 to 20 December 2021. Blackout/Surcharges may apply over special event periods and over school holidays. Inclusions are subject to change based on supplier operational capabilities. A 20% deposit is required to secure this package. Package starts Thursday only. Airport transfers not included. Quote package code: ANZAUPB21224DPGCE.',
          },
        ],
      },
      {
        title: "Queensland",
        deals: [
          {
            type: 'tile',
            link: null,
            thumbnail:
              "https://cdn.anzcro.com.au/wp-content/uploads/2021/07/5-day-city-to-island-paradise-1-150x150.jpg",
            name: "5 Day City to Island Paradise",
            price: "665",
            complement: "per person (twin share)",
            code: "ANZAUPB21225DCIP",
            location: "Brisbane (BNE)",
            valid: "01 Sep 2021 - 31 Mar 2022",
            details: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/07/5-day-city-to-island-paradise-1.jpg',
            terms: 'Package price is based on 2 people and priced in New Zealand Dollars. Pricing is correct at the time of submission but is subject to change without notice. Valid for travel from the 01 Sept – 16 Sept 21, 04 Oct – 02 Dec 21 & 22 Jan - 31 Mar 2022. Blackout dates: 17 Sept– 03 Oct 21 & 03 Dec 21 – 21 Jan 22. Other blackout/Surcharges may apply over special event periods and over school holidays. Inclusions are subject to change based on supplier operational capabilities. Airport Transfers not included. Quote package code: ANZAUPB21225DCIP.',
          },
          {
            type: 'tile',
            link: null,
            thumbnail:
              "https://cdn.anzcro.com.au/wp-content/uploads/2021/07/5-day-forest-to-beach-experience-1-150x150.jpg",
            name: "5 Day Forest to Beach Experience",
            price: "969",
            complement: "per person (twin share)",
            code: "ANZAUPB21225DFBE",
            location: "Gold Coast Airport (OOLA)",
            valid: "01 Sep 2021 - 31 Mar 2022",
            details: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/07/5-day-forest-to-beach-experience-1.jpg',
            terms: 'Package price is based on 2 people and priced in New Zealand Dollars. Pricing is correct at the time of submission but is subject to change without notice. Valid for travel from the 01 September 2021 to 31 March 2022. Blackout dates: 01 – 04 Oct 21 & 23 Dec 21 – 16 Jan 22. Other blackout/Surcharges may apply over special event periods and over school holidays. Inclusions are subject to change based on supplier operational capabilities. Flights not included. Car hire is with Hertz Car Rental and subject to an excess conditions and based on return Gold Coast Airport. Quote package code: ANZAUPB21225DFBE.',
          },
          {
            type: 'tile',
            link: null,
            thumbnail:
              "https://cdn.anzcro.com.au/wp-content/uploads/2021/07/5-day-sunshine-coast-island-explorer-1-150x150.jpg",
            name: "5 Day Sunshine Coast & Island Explorer",
            price: "1035",
            complement: "per person (twin share)",
            code: "ANZAUPB21225DSCIE",
            location: "Brisbane (BNE)",
            valid: "25 Oct 2021 - 31 Mar 2022",
            details: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/07/5-day-sunshine-coast-island-explorer-1.jpg',
            terms: 'Package price is based on 2 people and priced in New Zealand Dollars. Pricing is correct at the time of submission but is subject to change without notice. Valid for travel from the 25 October – 23 December 2021 & 27 January -  31 March 2022. Blackout dates: 28-31 Oct & 24 Dec 21 – 26 Jan 22. Other blackout/Surcharges may apply over special event periods and over school holidays. A non-refundable deposit is required this secure this package. Inclusions are subject to change based on supplier operational capabilities. Car hire is with Hertz Car Rental and subject to an excess conditions and based on return Brisbane Airport. Quote package code: ANZAUPB21225DSCIE.',
          },
          {
            type: 'link',
            link: 'https://issuu.com/holidayexperts/docs/ausbook_2021-22_nzd_web/19?fr=sNDliMjE0NDc5NjU',
            thumbnail:
              "https://cdn.anzcro.com.au/wp-content/uploads/2021/06/deal-thumbnail-apc-2.jpg",
            name: "6 Day Reconnect with Nature",
            price: "1569",
            complement: "per person (twin share)",
            code: "ANZAUPB21226DRWN",
            location: "Cairns (CNS)",
            valid: "01 Apr 2021 - 31 Mar 2022",
            details: null,
            terms: null,
          },
          {
            type: 'tile',
            link: null,
            thumbnail:
              "https://cdn.anzcro.com.au/wp-content/uploads/2021/07/5-day-reef-rainforest-island-hopper-1-150x150.jpg",
            name: "5 Day Reef & Rainforest Island Hopper",
            price: "1825",
            complement: "per person (twin share)",
            code: "ANZAUPB21225DRRIH",
            location: "Hervey Bay (HVB)",
            valid: "01 Sep 2021 - 31 Mar 2022",
            details: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/07/5-day-reef-rainforest-island-hopper-1.jpg',
            terms: 'Package price is based on 2 people and priced in New Zealand Dollars. Pricing is correct at the time of submission but is subject to change without notice. Valid for travel from the 01 September – 22 December 2021 & 03 January - 31 March 2022. A non-refundable deposit is required to secure this package. Blackout/Surcharges may apply over special event periods and over school holidays. Inclusions are subject to change based on supplier operational capabilities. Quote package code: ANZAUPB21225DRRIH.',
          },
        ],
      },
      {
        title: "Tasmania",
        deals: [
          {
            type: 'tile',
            link: null,
            thumbnail:
              "https://cdn.anzcro.com.au/wp-content/uploads/2021/06/Freycinet-Walk-NZD-SM-TIle-150x150.png",
            name: "5 Day Freycinet Experience Walk",
            price: "3359",
            complement: "per person (twin share)",
            code: "ANZAUPB21225DFEW",
            location: "Hobart (HBA)",
            valid: "01 Oct 2021 - 31 Mar 2022",
            details: 'https://cdn.anzcro.com.au/wp-content/uploads/2021/06/Freycinet-Walk-NZD-SM-TIle.png',
            terms: 'Pricing based on 2 adults twin share. Travel from 01 Oct 2021 to 31 Mar 2022. Featured rate based on travel in Oct 2021. Blockout dates and seasonable surcharges may apply. Guided Walk departs every Friday and Tuesday. A minimum of 4 travellers is required for the Guided Walk to operate. A moderate fitness level is required and guests can choose to walk 28-40km over the four days. Flight bookings on last day should depart after 6pm due to late arrival into Hobart. A non-refundable deposit is required at time of booking. Contact ANZCRO for details. ',
          },
        ],
      },
      {
        title: "Northern Territory",
        deals: [
          {
            type: 'link',
            link: 'https://issuu.com/holidayexperts/docs/ausbook_2021-22_nzd_web/44?fr=sNDliMjE0NDc5NjU',
            thumbnail:
              "https://cdn.anzcro.com.au/wp-content/uploads/2021/06/deal-thumbnail-apc-4.jpg",
            name: "5 Day Kakadu & Litchfield Expedition",
            price: "945",
            complement: "per person (twin share)",
            code: "ANZAUPB21225DKLE",
            location: "Darwin (DRW)",
            valid: "01 May 2021 - 31 March 2022",
            details: null,
            terms: null,
          },
          {
            type: 'link',
            link: 'https://issuu.com/holidayexperts/docs/ausbook_2021-22_nzd_web/48?fr=sNDliMjE0NDc5NjU',
            thumbnail:
              "https://cdn.anzcro.com.au/wp-content/uploads/2021/06/deal-thumbnail-apc-5.jpg",
            name: "4 Day Iconic Central Outback",
            price: "1089",
            complement: "per person (twin share)",
            code: "ANZAUPB21224DICO",
            location: "Ayers Rock Airport (AYQA)",
            valid: "01 Apr 2021 - 31 Mar 2022",
            details: null,
            terms: null,
          },
        ],
      },
      {
        title: "Western Australia",
        deals: [
          {
            type: 'link',
            link: 'https://issuu.com/holidayexperts/docs/ausbook_2021-22_nzd_web/52?fr=sNDliMjE0NDc5NjU',
            thumbnail:
              "https://cdn.anzcro.com.au/wp-content/uploads/2021/06/deal-thumbnail-apc-6.jpg",
            name: "7 Day Margaret River & Wave Rock Expedition",
            price: "999",
            complement: "per person (twin share)",
            code: "ANZAUPB21227DMRWR",
            location: "Perth (PER)",
            valid: "01 Apr 2021 - 31 Mar 2022",
            details: null,
            terms: null,
          },
        ],
      },
    ],
    selectedSection: 0,
    selectedDeal: 0,
  }),
  mounted: function () {
    const el = document.scrollingElement || document.documentElement;
    el.scrollTop = 0;
  },
  methods: {
    top() {
      document.documentElement.scrollTop = 0;
    },
    copyCode(event) {
      if (event) {
        let copyCode = event.target.closest(".v-chip__content").querySelector(".package-code");
        window.getSelection().selectAllChildren(copyCode);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyCode.innerHTML);
        this.copiedText = 'Package code copied to clipboard';
        this.copied = true;
      }
    },
    copyLocation(event) {
      if (event) {
        let copyLocation = event.target
          .closest(".v-chip__content")
          .querySelector(".package-start-location");
        window.getSelection().selectAllChildren(copyLocation);
        document.execCommand("copy");
        window.getSelection().removeAllRanges();
        //alert(copyLocation.innerHTML);
        this.copiedText = 'Package start location copied to clipboard';
        this.copied = true;
      }
    },
    selectDeal(section, deal) {
      this.selectedSection = section;
      this.selectedDeal = deal;
    },
  },
};
</script>

<style>
.gradient-bar {
  width: 100%;
  height: 9px;
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    from(#65b32e),
    color-stop(#d2d2d2),
    to(#30302f)
  );
  background: linear-gradient(90deg, #65b32e, #d2d2d2, #30302f);
}

.deals-content {
  margin-top: -60px;
}

.package-data .v-chip {
  color: #9e9e9e;
  margin-left: 0;
}

.package-data .v-chip .v-chip__content {
  padding-right: 6px;
}

.deal-thumbnail {
  width: 88px;
  height: 88px;
}

.home-hero img.campaign-title.stacked{
  width:240px;
  height: 150px;
}

@media (min-width: 600px) {
  .home-hero img.campaign-title.stacked{
    width:480px;
    height: 300px;
  }
}

@media (max-width: 1880px) {
  .home-hero .hero-caption {
    bottom: 36px;
  }
}
</style>
